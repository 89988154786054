<div id="container">
  <div id="content">
    <blitz-admin-nav></blitz-admin-nav>
    <div id="main">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div id="footer">
  </div>
</div>


