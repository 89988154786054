<div class="admin-nav">
  <div class="admin-nav__content">
        <span class="admin-nav__content__header">
          Menu
        </span>
    <a [routerLink]="item.routerLink"
       class="admin-nav__content__item"
       [ngClass]="{'admin-nav__content__item--action': item.type === 'action'}"
       *ngFor="let item of nav">
      <i class="admin-nav__content__item__icon" [ngClass]="item.icon"></i>
      {{ item.label }}
    </a>
  </div>
</div>
