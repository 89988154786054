<div class="admin-home">
  <div class="admin-home__logo">
    <i class="fas fa-chart-bar" aria-hidden="true"></i>
  </div>
  <div class="admin-home__title">
    Tableau de bord
  </div>
  <div class="admin-home__sub-title">
    Cette page servira à afficher des statistiques d'usage et de vente lié à la plateforme.
  </div>
</div>
