<blitz-top [title]="title" [banner]="banner"></blitz-top>
<section class="page password-reset-page runway">
  <div class="runway-wrapper">
    <div class="content-wrapper">
      <form [formGroup]="passwordResetForm" novalidate (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="field-group">
            <label class="center-block" for="new_password1">{{ 'Subscription 1.Password New' | translate:lang }} <span
              class=red-asterisk>*</span></label>
            <input class="form-control text-input" formControlName="new_password1" id="new_password1" type="password">
            <div *ngIf="passwordResetForm.get('new_password1').touched &&
                 (passwordResetForm.get('new_password1').hasError('required') ||
                 passwordResetForm.get('new_password1').hasError('minlength') ||
                 passwordResetForm.get('new_password1').hasError('weakPassword'))"
                 class="alert alert-danger">
              <div class="alert alert-danger">{{ 'Subscription 1.Password Error Title' | translate:lang }}</div>
              <div class="alert alert-danger">{{ 'Subscription 1.Password Error 1' | translate:lang }}</div>
              <div class="alert alert-danger">{{ 'Subscription 1.Password Error 2' | translate:lang }}</div>
            </div>
          </div>
          <div class="field-group">
            <label class="center-block"
                   for="new_password2">{{ 'Subscription 1.Password Confirmation' | translate:lang }} <span
              class=red-asterisk>*</span></label>
            <input class="form-control text-input" formControlName="new_password2" id="new_password2" type="password">
            <div
              *ngIf="(passwordResetForm.get('new_password2').touched &&
              (passwordResetForm.get('new_password2').hasError('required') ||
              passwordResetForm.get('new_password2').hasError('minlength') ||
              passwordResetForm.get('new_password2').hasError('weakPassword'))) ||
              passwordResetForm.get('new_password2').hasError('outerError') ||
              passwordResetForm.get('passwordEquality').hasError('notEquivalent')"
              class="alert alert-danger">
              {{ 'Subscription 1.Password 2 Error' | translate:lang }}
            </div>
          </div>
          <div class="other-errors"
               *ngIf="passwordResetForm.get('uid').hasError('outerError') || passwordResetForm.get('token').hasError('outerError')">
            <div *ngIf="passwordResetForm.get('uid').hasError('outerError')"
                 class="alert alert-danger">{{ passwordResetService.outerErrors['uid'] | translate:lang }}</div>
            <div *ngIf="passwordResetForm.get('token').hasError('outerError')"
                 class="alert alert-danger">{{ passwordResetService.outerErrors['token'] | translate:lang }}</div>
          </div>
        </div>
        <div class="buttons">
          <button class="common-btn blue" [class.disabled]="passwordResetForm.pristine || passwordResetForm.invalid"
                  [disabled]="passwordResetForm.pristine || passwordResetForm.invalid">{{ 'Password Reset.Continue' | translate:lang }}</button>
        </div>
      </form>
    </div>
  </div>
</section>
