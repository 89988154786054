<div class="step5-top"></div>
<section class="payment" *ngIf="success === null">
  <i class="payment__icon fas fa-spinner fa-pulse fa-3x fa-fw"></i>
  <p class="payment__text">
    Activation de votre compte en cours...<br/>
    Veuiller patienter
  </p>
</section>

<section class="success-div">
  <div class="payment success-div__part">
    <i class="payment__icon fas fa-check-circle"></i>
    <p class="payment__text">
      Votre compte est activé!
    </p>
    <p class="payment__text">
      Vous pouvez maintenant accéder à votre page de gestion de compte afin de demander un abonnement au Club de Presse Blitz.
    </p>
  </div>

  <div class="success-div__part">
    <blitz-login [showPage]="'true'" [displayPage]="'true'" [navInputLink]="'/mz/accounts/subscription'"></blitz-login>
  </div>
</section>

<section class="payment" *ngIf="success === false">
  <i class="payment__icon payment__icon--error fas fa-times-circle"></i>
  <p class="payment__text">
    Ce lien d'activation est invalide ou périmé.
  </p>
  <p class="payment__text">
    Vous pouvez contacter notre équipe technique à l'adresse <a href="mailto:info@datablitz.com">info@datablitz.com</a> afin qu'elle active votre compte manuellement.
  </p>
  <a class="common-btn blue" routerLink="/">
    Retour à l'accueil
  </a>
</section>
