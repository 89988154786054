import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import { CookieService } from 'ngx-cookie';

import { environment } from '../../environments/environment';
import { DefaultService } from './default.service';
import { SearchService } from './search.service';
import { Observable } from 'rxjs';
import { IGroupFavorite } from './model/group-favorite';
import * as _ from 'underscore';
import { Router } from '@angular/router';

@Injectable()
export class FavoritesService extends DefaultService {
  private headers: HttpHeaders;
  private token: string;

  // store current sorting option
  requestOrdering: string;
  favoriteListOrdering: string;

  constructor(private http: HttpClient,
              private searchService: SearchService,
              private router: Router,
              public cookieService: CookieService) {
    super(cookieService);
    this.setHeaders();
  }

  public getFavoritesRequest(userID = null, limit = 100, offset = 0, ordering = null): Observable<any> {
    this.setHeaders();
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/favorites/`;

    let params = new HttpParams();
    params = params.set('limit', limit.toString());
    params = params.set('offset', offset.toString());
    if (userID) {
      params = params.set('user', userID.toString());
    }
    if (ordering) {
      this.requestOrdering = ordering?.toString();
      params = params.set('ordering', ordering?.toString());
    } else {
      this.requestOrdering = null;
    }

    const options = {headers: this.headers, params};

    return this.http.get(
      url,
      options
    );
  }

  public getFavoritesRequestGroup(userID, limit = 100, offset = 0): Observable<any> {
    this.setHeaders();
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/groups/`;

    let params = new HttpParams();
    params = params.set('limit', limit.toString());
    params = params.set('offset', offset.toString());
    params = params.set('user', userID.toString());

    const options = {headers: this.headers, params};

    return this.http.get(
      url,
      options
    );
  }

  public getFavoritesList(userID = null, limit = 100, offset = 0, ordering = null): Observable<any> {
    this.setHeaders();
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/favoritelist/`;

    let params = new HttpParams();
    params = params.set('limit', limit.toString());
    params = params.set('offset', offset.toString());
    params = params.set('user', userID.toString());
    if (ordering) {
      this.favoriteListOrdering = ordering?.toString();
      params = params.set('ordering', ordering?.toString());
    } else {
      this.favoriteListOrdering = null;
    }

    const options = {headers: this.headers, params};

    return this.http.get(
      url,
      options
    );
  }

  public getFavoritesListGroup(user = null, limit = 100, offset = 0): Observable<any> {
    this.setHeaders();
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/favoritelistgroup/`;

    let params = new HttpParams();
    params = params.set('user', user.toString());
    params = params.set('limit', limit.toString());
    params = params.set('offset', offset.toString());

    const options = {headers: this.headers, params};

    return this.http.get(
      url,
      options
    );
  }

  public getFavoriteRequest(id: number): Observable<IGroupFavorite> {
    this.setHeaders();
    const options = {headers: this.headers};
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/favorites/${id}/`;
    return this.http.get<IGroupFavorite>(
      url,
      options
    );
  }

  public getFavoriteList(id: number, limit = 100, offset = 0): Observable<IGroupFavorite> {
    this.setHeaders();
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/favoritelist/${id}/`;

    let params = new HttpParams();
    params = params.set('limit', limit.toString());
    params = params.set('offset', offset.toString());

    const options = {headers: this.headers, params};

    return this.http.get<IGroupFavorite>(
      url,
      options
    );
  }

  public getFavoriteListItems(id: number, limit = 100, offset = 0, order = null): Observable<any> {
    this.setHeaders();
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/favoritelist/${id}/list_item`;

    let params = new HttpParams();
    params = params.set('limit', limit.toString());
    params = params.set('offset', offset.toString());

    if (order) {
      params = params.set(order.filterName, order.id.toString());
    }

    const options = {headers: this.headers, params};

    return this.http.get<any>(
      url,
      options
    );
  }

  private addFilterParams(data: any) {
    _.each(data, (param: any, name: string) => {
      _.each(param, (p: any) => {
        this.searchService.addSearchParams({filterName: name, id: (p.pk || p.id)});
      });
    });
  }

  public goToSearch(groupFavorite: IGroupFavorite) {
    if (!groupFavorite.query_data.params) {
      return;
    }
    this.searchService.clearSearchParams();
    this.searchService.cleanChips();
    this.searchService.addChips(groupFavorite.query_data.params.filters);
    this.searchService.setQueryParams({text: groupFavorite.query_data.params.search[0]});
    if (groupFavorite.query_data.params.limit) {
      this.searchService.addSearchParams({filterName: 'limit', id: groupFavorite.query_data.params.limit});
    }
    if (groupFavorite.query_data.params.offset) {
      this.searchService.addSearchParams({filterName: 'offset', id: groupFavorite.query_data.params.offset});
    }
    this.addFilterParams(groupFavorite.query_data.params.filters);
    this.searchService.setSearchType(groupFavorite.query_data.type, true);
    this.router.navigate(['/mz/search']).then();

  }

  public addNewFavoriteRequestGroups(name: string): Observable<any> {
    this.setHeaders();
    const options = {headers: this.headers};
    const body = {name};
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/groups/`;
    return this.http.post(
      url,
      body,
      options
    );
  }

  public addNewFavoriteListGroups(name: string): Observable<any> {
    this.setHeaders();
    const options = {headers: this.headers};
    const body = {name};
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/favoritelistgroup/`;
    return this.http.post(
      url,
      body,
      options
    );
  }

  public addFavorite(name: string, group: any): Observable<any> {
    this.setHeaders();
    const options = {headers: this.headers};
    const body = {name, group, uri: this.searchService.url.replaceAll('&', '&amp')};
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/favorites/`;
    return this.http.post(
      url,
      body,
      options
    );
  }

  addFavoriteList(name: string, group: any): Observable<any> {
    this.setHeaders();
    const options = {headers: this.headers};
    const body = {name, group, uri: this.searchService.url.replaceAll('&', '&amp')};
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/favoritelist/`;
    return this.http.post(
      url,
      body,
      options
    );
  }

  addFavoriteItem(favoriteList: any, itemID: any, type: string): Observable<any> {
    this.setHeaders();

    const typesArray = ['MEDIA', 'SERVICE', 'CONTACT'];

    let typeFormatted = '';
    for (const searchType of environment.SEARCH_TYPES) {
      for (const [key, value] of Object.entries(searchType)) {
        if (type === value) {
          typeFormatted = typesArray[environment.SEARCH_TYPES.indexOf(searchType)];
        }
      }
    }

    const options = {headers: this.headers};
    const body = {type: typeFormatted, item_pk: itemID};
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/favoritelist/${favoriteList.id}/add_item`;
    return this.http.post(
      url,
      body,
      options
    );
  }

  public saveGroup(data: any): Observable<any> {
    this.setHeaders();
    const options = {headers: this.headers};
    const name = data.isFavorite ? 'favorites' : 'groups';
    const body = {name: data.name};
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/${name}/${data.item.pk}/`;
    return this.http.patch(
      url,
      body,
      options
    );
  }

  public updateFavorite(data: any): Observable<any> {
    this.setHeaders();
    const options = {headers: this.headers};
    const name = data.isFavoriteRequest ? 'favorites' : 'favoritelist';
    const body = {name: data.name, group: data.group.id};
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/${name}/${data.item.id}/`;
    return this.http.patch(
      url,
      body,
      options
    );
  }

  removeFavoriteListItem(favoriteList: any, item: any): Observable<any> {
    this.setHeaders();

    const options = {headers: this.headers};
    const body = {type: item.type_of_item.toUpperCase(), item_pk: item.pk};
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/favoritelist/${favoriteList.id}/remove_item`;
    return this.http.post(
      url,
      body,
      options
    );
  }

  public deleteFavorite(data: any): Observable<any> {
    this.setHeaders();
    const options = {headers: this.headers};
    const name = data.query_data ? 'favorites' : 'favoritelist';
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/${name}/${data.id}`;
    return this.http.delete(
      url,
      options
    );
  }

  // todo: refactor this part
  // Was used on deleteGroup() error
  /*
    private errorsHandler(err: any, name: string) {
      if (err && err.status === 500) {
        this.sharedDataService.setMessageHandel({
          message: `MZ Manage Favorites.${name}`,
          status: 'error'
        });
      }
    }
  */

  private setHeaders() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
    this.headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Token ${this.token}`
    });
  }

  saveRequestAsFavoriteList(requestUri: string, filters: any, name: string, groupId: string ): Observable<any> {
    this.setHeaders();

    let filterString = '';
    if (Object.keys(filters).length) {
      _.each(this.searchService.searchParams, (param: any, filterName: string) => {
        filterString += `&${filterName}=${param.join(',')}`;
      });
    }

    const url = requestUri + filterString + '&group=' + groupId.toString() + '&list=' + name;

    const options = {headers: this.headers};
    return this.http.get(
      url,
      options
    );
  }

  exportFavoriteList(favoriteListId: any, exportType: any): Observable<any> {
    this.setHeaders();

    const options = {headers: this.headers};
    const url = `${environment.API_URL}/${this.lang}/api/v1/users/favoritelist/${favoriteListId}/export_to/?export=${exportType}`;
    return this.http.get(
      url,
      options
    );
  }
}
