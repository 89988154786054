import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';

import { currentUserStub } from './models.stub';
import { CurrentUser } from '../model/current-user';

@Injectable()
export class AuthServiceStub {

  public getCurrentUser(): CurrentUser {
    return currentUserStub;
  }

  public getFullUserInfo(): Observable<CurrentUser> {
    let stubUser = new BehaviorSubject<CurrentUser>(null);
    stubUser.next(currentUserStub);
    return stubUser.asObservable();
  }
}
