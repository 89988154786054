import { Injectable } from '@angular/core';
import GlobalService from './globalService';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { Payment } from '../shared/model/payment';
import {CookieService} from 'ngx-cookie';

@Injectable()
export class PaymentService extends GlobalService {

  constructor(public http: HttpClient,
              public cookieService: CookieService) {
    super(cookieService);
  }

  approve(id: number): Observable<any> {
    const headers = this.getHeaders();
    return this.http.patch<any>(
      environment.API_URL + environment.API.payments + id + '/',
      {
        completed: true
      },
      {headers: headers}
    );
  }

  patch(payment: Payment) {
    const headers = this.getHeaders();
    return this.http.patch<any>(
      environment.API_URL + environment.API.payments + payment.id + '/',
      payment,
      {headers: headers}
    );
  }

  chargeCard(payment: Payment): Observable<any> {
    const headers = this.getHeaders();
    return this.http.post<any>(
      environment.API_URL + environment.API.payments + payment.id + '/charge_card',
      {},
      {headers: headers}
    );
  }

  getDefaultCard(userId): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(
      `${environment.API_URL}/en/api/v1/users/` + userId + `/default_card`,
      {headers: headers}
    );
  }
}
