<div class="main-wrapper search-bar search-bar-header">
  <div class="desktop">
    <div class="type-search-input-wrapper">
      <mdl-select [(ngModel)]="selectedType" id="header-type-select" class="mz-select" (change)="onChangeSelectedType()">
        <mdl-option *ngFor="let searchType of searchTypes" [value]="searchType.name">{{ searchType.text_fr }}</mdl-option>
      </mdl-select>
    </div>
    <div class="search-input-wrapper">
      <input class="form-control text-input"
             [(ngModel)]="searchText"
             (keyup.enter)="onSearch()"
             id="search-input" placeholder="{{ 'MZ Header.Search Placeholder' | translate:lang }}">
      <i class="fas fa-search" (click)="onSearch()"></i>
    </div>
    <div class="information-popup-wrapper" (click)="searchInfoPopover.toggle($event)">
      <i class="fas fa-info"></i>
    </div>
    <blitz-popover class="blitz-popover" #searchInfoPopover mdl-shadow="8">
      <div class="info-popover-content">
        <div class="info-popover-text-block">{{ 'MZ Search Bar.DataBlitz Search Placeholder' | translate:lang }}</div>
      </div>
    </blitz-popover>
  </div>
  <div class="mobile" *ngIf="member?.data">
    <div class="left-side">
      <div class="member-name-wrapper" (click)="accPopover.toggle($event)">
        <div class="member-icon"><img src="../../../assets/img/header-sign-in-icon.png"></div>
        <div class="member-name">
          {{ 'MZ Header.Hello' | translate:lang }}{{ member?.data?.first_name || member.email}}
        </div>
        <blitz-popover class="blitz-popover" #accPopover mdl-shadow="8">
          <div class="info-popover-content">
            <div class="info-popover-text-block">
              <div><a routerLink="/mz/accounts/my">{{ 'Header.My Account' | translate:lang }}</a></div>
              <div><a routerLink="/mz/accounts/all" *ngIf="member?.data?.is_manager">{{ 'Header.All Accounts' | translate:lang }}</a></div>
              <div><a (click)="authService.logout()">{{ 'Header.Logout' | translate:lang }}</a></div>
            </div>
          </div>
        </blitz-popover>
      </div>
    </div>
    <div class="right-side">
      <div class="search-icon-wrapper" routerLink="/mz"><i class="fas fa-search"></i></div>
    </div>
  </div>
</div>
