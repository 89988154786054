import * as _ from 'underscore';
import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { TranslationService } from 'angular-l10n';

export interface MenuListDto {
  notLogged: {
    id: string;
    title: string;
    link: string;
    external_link: string;
  }[];
  logged: {
    id: string;
    title: string;
    link: string;
    external_link: string;
  }[];
}

@Injectable()
export class SharedDataService {
  private queryHandle = new BehaviorSubject<{ [key: string]: string }>(null);
  private centeredPopoverHandle = new BehaviorSubject<string>(null);
  private updateFilterHandle = new BehaviorSubject<any>(null);
  private menuListTypes: MenuListDto;
  private favoriteFilters: any;
  private pagCurrentPage: { [key: string]: number };
  private commonOrderTypes: Array<{ [key: string]: string }>;
  private orderTypes: {[key: string]: Array<{ [key: string]: string }>};

  constructor(private translation: TranslationService) {
    this.pagCurrentPage = {page: 1};
    this.menuListTypes = {
      notLogged: [
        { 'id': 'services', 'title': 'Services/Tarifs', 'link': '/services', 'external_link': '' },
        { 'id': 'about', 'title': 'À propos ', 'link': '/about', 'external_link': '' },
        { 'id': 'contact', 'title': 'Contact', 'link': '/contact', 'external_link': '' },
      ],
      logged: [
        { 'id': 'dashboard', 'title': 'Tableau de bord', 'link': '/mz/dashboard', 'external_link': '' },
        { 'id': 'ptite_vite', 'title': 'P\'tites Vites', 'link': '/mz/ptites-vites', 'external_link': '' },
        { 'id': 'influencers', 'title': 'Influencers', 'link': '/mz/influencers', 'external_link': '' },
        {
          'id': 'media_match',
          'title': 'Media Match',
          'link': '',
          'external_link': 'https://www.blog.datablitz.com/media-match-beta'
        }
      ]
    };

    this.commonOrderTypes = [
      { name: 'Courriel', id: 'email', filterName: 'ordering' },
      { name: 'Téléphone', id: 'phone', filterName: 'ordering' },
      { name: 'Télécopieur', id: 'fax', filterName: 'ordering' }
    ];

    this.orderTypes = {
      media: [
        { name: 'Nom du média', id: 'title', filterName: 'ordering' },
        { name: 'Langue de média', id: 'languages', filterName: 'ordering' },
        { name: 'Propriétaire média', id: 'owner', filterName: 'ordering' },
        { name: 'Type de média', id: 'target_type', filterName: 'ordering' }
      ],
      services: [
        { name: 'Nom du contact', id: 'contact_name', filterName: 'ordering' },
        { name: 'Média', id: 'media', filterName: 'ordering' },
        { name: 'Type d’émission', id: 'type', filterName: 'ordering' },
        { name: 'Langue de média', id: 'languages', filterName: 'ordering' }
      ],
      contacts: [
        { name: 'Prénom', id: 'first_name', filterName: 'ordering' },
        { name: 'Nom', id: 'last_name', filterName: 'ordering' },
        { name: 'Fonctions', id: 'functions', filterName: 'ordering' },
        { name: 'Spécialité', id: 'domaines', filterName: 'ordering' }
      ]
    };
    this.combineWithCommonTypes();
    this.sortOrderTypes();
  }


  public setFavoriteFilters(filters: any) {
    return this.favoriteFilters = filters;
  }

  public getFavoriteFilters(): any {
    return this.favoriteFilters;
  }

  public getMenuListTypes() {
    return this.menuListTypes;
  }

  public getOrderTypes() {
    return this.orderTypes;
  }

  public getPagCurrentPage(): {[key: string]: number} {
    return this.pagCurrentPage;
  }

  public setPagCurrentPage(num: {[key: string]: number}): void {
    this.pagCurrentPage = num;
  }

  public getMessageHandel(): Observable<{ [key: string]: string }> {
    return this.queryHandle.asObservable();
  }

  public setMessageHandel(content?: any): void {
    this.queryHandle.next(content);
  }

  public getCenteredPopoverMessage(): Observable<string> {
    return this.centeredPopoverHandle.asObservable();
  }

  public setCenteredPopoverMessage(content?: string): void {
    this.centeredPopoverHandle.next(content);
  }

  public getNeedUpdateFilter(): Observable<string> {
    return this.updateFilterHandle.asObservable();
  }

  public setNeedUpdateFilter(content?: any): void {
    this.updateFilterHandle.next(content);
  }

  private combineWithCommonTypes() {
    _.each(this.orderTypes, ((t: Array<{ [key: string]: string }>) => {
      t.push(...this.commonOrderTypes);
    }));
  }

  private sortOrderTypes() {
    this.orderTypes = _.mapObject(this.orderTypes, (val) => {
      return _.sortBy(val, 'name');
    });
  }
}
