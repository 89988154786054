<div class="modal-component"
     (click)="clickOverlay($event)"
     [style.display]="!show ? 'none' : 'block'"
     [ngClass]="{'modal-component--danger': modalStyle === 'danger'}">
  <div class="modal-component__content">
    <div class="modal-component__content__reveal" [style.width]="maxWidth">
      <div class="modal-component__content__reveal__header">
        {{ title | translate }}
        <div class="modal-component__content__reveal__header__spacer"></div>
        <img src="assets/img/close.svg" class="fas fa-times modal-component__content__reveal__header__cancel" (click)="toggle()" />
      </div>
      <div class="modal-component__content__reveal__content" #modalContent>
        <ng-content></ng-content>
      </div>
      <div class="modal-component__content__reveal__buttons">
        <button [disabled]="!activated"
                class="modal-component__content__reveal__buttons__button"
                (click)="clickButton()"
                [ngClass]="{'modal-component__content__reveal__content__buttons__button--danger': modalStyle === 'danger', 'modal-component__content__reveal__content__buttons__button--disabled': !activated}">
          {{ getLabelButton() | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
