import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie';
import { environment } from '../../environments/environment';
import { AuthService, DefaultService, FavoritesService } from './';
import { Observable } from 'rxjs';
import { INotification } from './model/notification';
import { ResponseApi } from './model/ResponseAPI';

@Injectable()
export class NotificationsService extends DefaultService {
  private headers: HttpHeaders;
  private token: string;
  constructor(private http: HttpClient,
              public cookieService: CookieService,
              private authService: AuthService,) {
    super(cookieService);
    this.setHeaders();
  }
  public getUnreadNotifications(limit?: string, offset?: string | string): Observable<ResponseApi<INotification>> {
    this.setHeaders();
    let url, options = {headers: this.headers};
    const user = this.authService.getCurrentUser();
    url = `${environment.API_URL}/${this.lang}/api/v1/users/notifications/?owner=` + user.data.id;
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (offset) {
      url += `&offset=${offset}`;
    }
    return this.http.get<ResponseApi<INotification>>(
      url,
      options
    );
  }
  public setNotificationAsRead(id: number): Observable<INotification> {
    this.setHeaders();
    let url, options = {headers: this.headers};
    let body = {
      'state': 'R',
    };
    url = `${environment.API_URL}/${this.lang}/api/v1/users/notifications/${id}/`;
    return this.http.patch<INotification>(
      url,
      body,
      options
    );
  }

  // todo: refactor this part
  /*
    private errorsHandler(err: any, name: string) {
      if (err && err.status === 500) {
        this.sharedDataService.setMessageHandel({
          message: `MZ Manage Notifications.${name}`,
          status: 'error'
        });
      }
    }
  */

  private setHeaders() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
    this.headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Token ${this.token}`
    });
  }
}
