import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';

import { DefaultService } from '../shared/default.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AccountsService extends DefaultService {
  private headers: HttpHeaders;
  private token: string;

  constructor(public cookieService: CookieService,
              private http: HttpClient) {
    super(cookieService);
  }

  public getSubMember(id: string | number): Observable<any> {
    this.setHeaders();
    return this.http.get(
      `${environment.API_URL}/${this.lang}/api/v1/users/members/${id}/`,
      {headers: this.headers}
    );
  }

  public getMember(id: string | number, is_manager: boolean): Observable<any> {
    this.setHeaders();
    let membersOrAccounts = is_manager ? 'accounts' : 'members';
    return this.http.get(
      `${environment.API_URL}/${this.lang}/api/v1/users/${membersOrAccounts}/${id}/`,
      {headers: this.headers}
    );
  }

  public getMediaDetailView(id: string | number): Observable<any> {
    this.setHeaders();
    return this.http.get(
      `${environment.API_URL}/${this.lang}/api/v1/media/${id}/`,
      {headers: this.headers}
    );
  }

  public getServicesDetailView(id: string | number): Observable<any> {
    this.setHeaders();
    return this.http.get(
      `${environment.API_URL}/${this.lang}/api/v1/services/${id}/`,
      {headers: this.headers}
    );
  }

  public updateSubMember(id: string | number, value: any): Observable<any> {
    this.setHeaders();
    return this.http.patch(
      `${environment.API_URL}/${this.lang}/api/v1/users/members/${id}/`,
      value,
      {headers: this.headers}
    );
  }

  public createSubMember(value: any): Observable<any> {
    this.setHeaders();
    return this.http.post(
      `${environment.API_URL}/${this.lang}/api/v1/users/members/registration/`,
      value,
      {headers: this.headers}
    );
  }

  public deleteSubMember(id: string | number): Observable<any> {
    this.setHeaders();
    return this.http.delete(
      `${environment.API_URL}/${this.lang}/api/v1/users/members/registration/`,
      {headers: this.headers}
    );
  }

  public updateMember(id: string | number, is_manager: boolean, value: any): Observable<any> {
    this.setHeaders();
    let membersOrAccounts = is_manager ? 'accounts' : 'members';
    return this.http.patch(
      `${environment.API_URL}/${this.lang}/api/v1/users/${membersOrAccounts}/${id}/`,
      value,
      {headers: this.headers}
    );
  }

  public changeCard(id: string | number, source: string): Observable<any> {
    this.setHeaders();
    return this.http.patch(
      `${environment.API_URL}/${this.lang}/api/v1/users/${id}/change_card/`,
      {
        source
      },
      {headers: this.headers}
    );
  }

  private setHeaders() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
    this.headers = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': `Token ${this.token}`
    });
  }
}
