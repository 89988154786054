import { Component, OnInit } from '@angular/core';
import { Language, TranslationService } from 'angular-l10n';

import { environment } from '../../../environments/environment';
import { SubscriptionFormValuesService } from '../subscription-form-values.service';
import { RegistrationService } from '../registration.service';
import { Router } from '@angular/router';

@Component({
  selector: 'blitz-subscription-step-3',
  templateUrl: './subscription-step-3.component.html',
  styleUrls: ['./subscription-step-3.component.scss', '../subscription-steps.shared.scss']
})
export class SubscriptionStep3Component implements OnInit {
  @Language() lang: string;
  agreementConfirmed: boolean;
  mailAgreementConfirmed: boolean;
  email = environment.INFO_EMAIL;
  disableBtn: boolean;

  constructor(public subscriptionFormValuesService: SubscriptionFormValuesService,
              public translation: TranslationService,
              private registrationService: RegistrationService,
              private router: Router) {
    this.agreementConfirmed = this.subscriptionFormValuesService.isAgreementConfirmed;
    this.mailAgreementConfirmed = this.subscriptionFormValuesService.isMailAgreementConfirmed;
    this.disableBtn = true;
  }

  ngOnInit() { }

  onToggleAgreeCheckbox(isConfirmed: boolean) {
    this.subscriptionFormValuesService.isAgreementConfirmed = isConfirmed;
    this.subscriptionFormValuesService.value['tos_agreement'] = isConfirmed;
  }

  onToggleMailAgreeCheckbox(isConfirmed: boolean) {
    this.subscriptionFormValuesService.isMailAgreementConfirmed = isConfirmed;
    this.subscriptionFormValuesService.value['newsletter_agreement'] = isConfirmed;
  }

  onSubmitForm() {
    this.setDisable(true);
    this.registrationService
      .submitValues()
      .then((resolved) => this.handleSubmitSuccess(resolved))
      .catch((rejected) => this.handleSubmitError(rejected));
  }

  private setDisable(val: boolean) {
    this.disableBtn = val;
  }

  private handleSubmitSuccess(resolved) {
    this.setDisable(false);
    this.router.navigateByUrl('/subscribe/step-4').then();
  }

  private handleSubmitError(rejected) {
    this.setDisable(false);
    this.subscriptionFormValuesService.errors = rejected.error;
    this.router.navigateByUrl('/subscribe/step-1').then();
  }
}
