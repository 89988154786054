import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'blitz-credit-card-icon',
  templateUrl: './credit-card-icon.component.html',
  styleUrls: ['./credit-card-icon.component.scss']
})
export class CreditCardIconComponent implements OnInit {

  // A credit card source from Stripe
  @Input() creditCard;

  // Boolean to say if it's a new card
  @Input() newCard = false;

  constructor() { }

  ngOnInit() {
  }
}
