
import {filter, takeUntil} from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, Event as RouterEvent } from '@angular/router';

import { Language } from 'angular-l10n';
import { Subject } from 'rxjs';


import { CurrentUser, SearchService, AuthService } from '../../shared';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'blitz-second-header',
  templateUrl: './second-header.component.html',
  styleUrls: ['../../member-zone/mz.scss', './second-header.component.scss'],
})
export class SecondHeaderComponent implements OnInit, OnDestroy {
  @Output() public onSearchEmitter = new EventEmitter();
  @Language() lang: string;
  textLang: string;
  searchTypes: Array<any> = environment.SEARCH_TYPES;
  selectedType: string;
  member: CurrentUser;
  searchText: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(public authService: AuthService,
              private router: Router,
              private searchService: SearchService) {
  }

  public ngOnInit() {
    this.textLang = `text_${this.lang}`;

    this.authService.getFullUserInfo().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.member = data;
      });

    this.searchService.getSearchType().pipe(
      takeUntil(
        this.ngUnsubscribe
      )
    ).subscribe(
      (data) => {
        this.selectedType = data ? data : this.searchTypes[2].name;
      }
    );

    this.router.events.pipe(
      filter(
        (data: RouterEvent) => {
          return data instanceof NavigationEnd;
        }
      ),
      takeUntil(
        this.ngUnsubscribe
      )
    ).subscribe(
      () => {
        this.searchText = '';
      }
    );
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onSearch() {
    this.onSearchEmitter.emit(this.searchText);
  }

  public onChangeSelectedType() {
    // this.searchService.clearSearchParams();  //  REMOVED
    this.searchService.setSearchType(this.selectedType);
  }
}
