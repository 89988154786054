import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { SubscriptionFormValuesService } from './subscription-form-values.service';
import { AuthService } from '../shared';

@Injectable()
export class ValidFormGuard implements CanActivate {
  constructor(
    private subscriptionFormValuesService: SubscriptionFormValuesService,
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(next?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Promise<boolean> | boolean {
    const user = this.authService.getCurrentUser();
    if (user && user.data && !user.data.subscriptions) {
      this.router.navigate(['/subscribe/step-5']).then();
      return false;
    }
    if (user && user.data) {
      this.router.navigate(['/']).then();
      return false;
    }
    return this.checkIfFormIsValid(state, next);
  }

  private checkIfFormIsValid(state: RouterStateSnapshot, next?: ActivatedRouteSnapshot): boolean {
    if (state.url.includes('step-1')) { return true; }
    if (this.subscriptionFormValuesService.isFormValid) { return true; }
    this.router.navigate(['/subscribe/step-1']).then();
    return false;
  }
}
