<section class="step-bar">
  <div class="circle" [ngClass]="stepDirector[0]"></div>
  <div class="line" [ngClass]="stepDirector[1]"></div>
  <div class="circle" [ngClass]="stepDirector[1]"></div>
  <div class="line" [ngClass]="stepDirector[2]"></div>
  <div class="circle" [ngClass]="stepDirector[2]"></div>
  <div class="line" [ngClass]="stepDirector[3]"></div>
  <div class="circle" [ngClass]="stepDirector[3]"></div>
  <div class="line" [ngClass]="stepDirector[4]"></div>
  <div class="circle" [ngClass]="stepDirector[4]"></div>
</section>
