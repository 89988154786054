import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ServicesComponent } from './services/services.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { SubscriptionStepsComponent } from './subscription-steps/subscription-steps.component';
import { SubscriptionStep1Component } from './subscription-steps/subscription-step-1/subscription-step-1.component';
import { SubscriptionStep2Component } from './subscription-steps/subscription-step-2/subscription-step-2.component';
import { SubscriptionStep3Component } from './subscription-steps/subscription-step-3/subscription-step-3.component';
import { SubscriptionStep4Component } from './subscription-steps/subscription-step-4/subscription-step-4.component';
import { SubscriptionStep5Component } from './subscription-steps/subscription-step-5/subscription-step-5.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { ValidFormGuard } from './subscription-steps/valid-form.guard';
import { NavigateOutsideFormGuard } from './subscription-steps/navigate-outside-form.guard';
import { AuthGuard } from './member-zone/auth.guard';
import { AdminLayoutComponent } from './admin/admin-layout/admin-layout.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { AdminSubscriptionComponent } from './admin/subscription/admin-subscription/admin-subscription.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { IsAdminGuard } from './admin/is-admin.guard';
import { AdminSubscriptionListComponent } from './admin/subscription/admin-subscription-list/admin-subscription-list.component';
import { AdminSubscriptionDetailComponent } from './admin/subscription/admin-subscription-detail/admin-subscription-detail.component';
import { AdminSubscriptionCreateComponent } from './admin/subscription/admin-subscription-create/admin-subscription-create.component';
import { LoginPageComponent } from './login-page/login-page.component';

const routes: Routes = [
  {
    path: '', component: DefaultLayoutComponent, children: [
      {path: '', component: HomeComponent},
      {path: 'login', component: LoginPageComponent},
      {path: 'about', component: AboutComponent},
      {path: 'contact', component: ContactComponent},
      {path: 'services', component: ServicesComponent},
      {path: 'password/confirm/:uid/:token', component: PasswordResetComponent},
      {
        path: 'subscribe', component: SubscriptionStepsComponent,
        children: [
          {path: '', component: SubscriptionStep1Component},
          {path: 'step-1', component: SubscriptionStep1Component, canActivate: [ValidFormGuard], canDeactivate: [NavigateOutsideFormGuard]},
          {path: 'step-2', component: SubscriptionStep2Component, canActivate: [ValidFormGuard], canDeactivate: [NavigateOutsideFormGuard]},
          {path: 'step-3', component: SubscriptionStep3Component, canActivate: [ValidFormGuard], canDeactivate: [NavigateOutsideFormGuard]},
          {path: 'step-4', component: SubscriptionStep4Component, canActivate: [ValidFormGuard], canDeactivate: [NavigateOutsideFormGuard]},
        ]
      },
      {path: 'subscribe/step-5/:activationToken', component: SubscriptionStep5Component},

      {
        path: 'mz',
        loadChildren: () => import('./member-zone/member-zone.module').then(m => m.MemberZoneModule),
        canActivate: [AuthGuard]},
    ]
  },
  {
    path: 'admin', component: AdminLayoutComponent, canActivate: [IsAdminGuard], children: [
      {path: '', component: AdminHomeComponent},
      {
        path: 'subscription', component: AdminSubscriptionComponent, children: [
          {path: '', component: AdminSubscriptionListComponent},
          {path: 'create', component: AdminSubscriptionCreateComponent},
          {path: ':id', component: AdminSubscriptionDetailComponent},
        ]
      }
    ]
  },
  {path: '**', component: PageNotFoundComponent}
];

export const routing = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
