import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LocaleService, TranslationService, Language } from 'angular-l10n';
import 'autotrack/autotrack.js';
import { environment } from '../environments/environment';


import '../assets/style/app.scss';
declare let ga: Function;

@Component({
  selector: 'blitz-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @Language() lang: string;
  title: string;

  constructor(public locale: LocaleService,
              public translation: TranslationService,
              private router: Router) {
    sessionStorage.clear();
    this.title = environment.TITLE;
    this.selectLanguage('fr');
  }

  ngOnInit() {
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        ga('require', 'urlChangeTracker', {
          shouldTrackUrlChange: function(newPath, oldPath) {
            newPath = newPath.split('?')[0];
            oldPath = oldPath.split('?')[0];
            return newPath !== oldPath;
          }
        });
      }
    });
  }

  selectLanguage(language: string): void {
    this.locale.setCurrentLanguage(language);
  }
}
