<div class="login-modal" *ngIf="showModal || showPage" [mdl-shadow]="8" [ngClass]="{'display-page': displayPage}">
  <button *ngIf="!displayPage" class="login-modal__close" (click)="onClose()">
    &#10005;
  </button>
  <form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmitLoginForm()" action="" *ngIf="!showResetPassForm"
        [ngClass]="{'error': loginForm.hasError('errors')}">
    <div class="login-modal__title">{{ 'Login.Login Title' | translate:lang }}</div>
    <div class="form-group">
      <input type="text" name="email" formControlName="email" value=""
             placeholder="{{ 'Login.Login Placeholder' | translate:lang }}" class="login-modal__input text-input">
      <div *ngIf="loginForm.get('email').touched && loginForm.get('email').hasError('required')"
           class="alert alert-danger">{{ 'Subscription 1.Email' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
      <div *ngIf="loginForm.get('email').hasError('outerError')"
           class="alert alert-danger">{{ loginService.outerErrors['email'] }}</div>
    </div>
    <div class="form-group">
      <input type="password" name="password" formControlName="password" id="db-password"
             placeholder="{{ 'Subscription 1.Password' | translate:lang }}" class="login-modal__input text-input">
      <div *ngIf="loginForm.get('password').touched && loginForm.get('password').hasError('required')"
           class="alert alert-danger">{{ 'Subscription 1.Password' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
      <div *ngIf="loginForm.get('password').hasError('outerError')"
           class="alert alert-danger">{{ loginService.outerErrors['password'] }}</div>
    </div>

    <div *ngIf="loginForm.hasError('errors')">
      <div class="alert alert-danger" *ngFor="let error of loginForm.getError('errors')">
        {{ error }}</div>
    </div>

    <div class="forget-link__wrapper">
      <a href="javascript:void(0)" (click)="toggleResetPass()"
         class="forget-link">{{ 'Login.Forgot Password' | translate:lang }}</a>
    </div>
    <button type="submit" class="login-modal__submit"
            [disabled]="disableLoginBtn">{{ 'Login.Sign In' | translate:lang }}</button>

    <span *ngIf="emailSent" class="login-modal__email-sent">{{ 'Login.Sent Email Confirmation' | translate:lang}}</span>
  </form>

  <form [formGroup]="resetPassForm" novalidate (ngSubmit)="onSubmitResetForm()" action="" *ngIf="showResetPassForm"
        [ngClass]="{'error': resetPassForm.hasError('incorrectEmail')}">
    <div class="login-modal__title">{{ 'Login.Recovery Title' | translate:lang }}</div>

    <div class="form-group" [ngClass]="{'form-group--no-margin': emailSent}">
      <input type="text" name="email" formControlName="email" value=""
             [attr.disabled]="emailSent? true : null"
             placeholder="{{ 'Login.Reset Placeholder' | translate:lang }}" class="login-modal__input text-input">

      <div *ngIf="resetPassForm.get('email').touched && resetPassForm.get('email').hasError('required')"
           class="alert alert-danger">
        {{ 'Subscription 1.Email' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}
      </div>

      <div *ngIf="resetPassForm.errors">
        <div class="alert alert-danger">
          {{ 'Subscription 1.No user with this email' | translate:lang }} {{resetPassForm.value.email}}
        </div>
      </div>

      <ng-container *ngIf="!showResendEmail; else resentEmail">
        <ng-container *ngIf="emailSent">
          <div class="explanation">
            {{ 'Login.Reset Email sent' | translate:lang }}
          </div>

          <a href="javascript:void(0)" (click)="toggleResendEmail()"
             class="forget-link text-center">{{ 'Login.Did not receive email' | translate:lang }}</a>
        </ng-container>
      </ng-container>
      <ng-template #resentEmail>
        <span class="normal-text">
          {{ 'Login.Email sent check spam' | translate:lang }}
        </span>

        <button type="submit" class="login-modal__submit"
                [ngClass]="{'login-modal__submit--disabled': disableResendBtn}"
                [disabled]="disableResendBtn">
          {{ 'Login.Resend Email' | translate:lang }}
        </button>

        <span class="normal-text text-center" *ngIf="disableResendBtn">
          {{ 'Login.Button will be available in' | translate:lang }}
          {{ disableResendBtnDuration }}
          {{ 'Login.Minutes' | translate:lang }}
        </span>
      </ng-template>
    </div>

    <button type="submit" class="login-modal__submit" *ngIf="!emailSent"
            [disabled]="disableResetBtn">{{ 'Login.Send Email' | translate:lang }}
    </button>
  </form>
</div>
