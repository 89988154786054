export class Addon {
  id?: number;
  manager?: number;
  plan: IAddonPricePlan;
  amount?: number;
  is_active?: boolean;
  stripe_id?: string;
  stripe_item_id?: string;
  price?: number;
  title?: string;
}

export interface IAddonPricePlan {
    id: number;
    title: string;
    price: number;
    stripe_id: string;
    addon_type: string;
    is_added_for_free: number;
    selected?: boolean;
}
