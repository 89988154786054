import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import GlobalService from './globalService';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DefaultService, IRequestSubscription, Subscription } from '../shared';
import { SubscriptionFormValuesService } from '../subscription-steps/subscription-form-values.service';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class SubscriptionService extends GlobalService {

  constructor(public http: HttpClient,
              public cookieService: CookieService) {
    super(cookieService);
  }

  list(filters: {name: string, value: any}[] = null, limit = 100, offset = 0): Observable<any> {
    const headers = this.getHeaders();
    let params = new HttpParams();
    params = params.set('limit', limit.toString());
    params = params.set('offset', offset.toString());
    if (filters != null) {
      for (const filter of filters) {
        if (filter.name === 'is_approved') {
          params = params.set('is_approved', filter.value);
        }
        if (filter.name === 'auto_renew') {
          params = params.set('auto_renew', filter.value);
        }
        if (filter.name === 'payment__completed') {
          params = params.set('payment__completed', filter.value);
        }
        if (filter.name === 'search') {
          params = params.set('search', filter.value);
        }
      }
    }
    return this.http.get<any>(
      environment.API_URL + '/' + this.lang + environment.API.subscriptions,
      {headers: headers, params: params}
    );
  }

  listMembers(subscriptionId: number): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get<any>(
      environment.API_URL + '/' + this.lang + environment.API.subscriptions + `${subscriptionId}/members`,
      {headers: headers}
    );
  }

  estimate(userToken, data): Promise<any> {
    const headers = this.getHeaders();
    return this.http.post(
        `${environment.API_URL}/fr/api/v1/subscriptions/request/estimate/`,
        data,
        { headers: headers }
      )
      .toPromise();
  }

  get(id: number): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get<any>(
      environment.API_URL + '/' + this.lang + environment.API.subscriptions + id + '/',
      {headers: headers}
    );
  }

  compare(id: number): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get<any>(
      environment.API_URL + '/' + this.lang + environment.API.subscriptions + id + '/compare',
      {headers: headers}
    );
  }

  approve(id: number): Observable<any> {
    const headers = this.getHeaders();
    return this.http.patch<any>(
      environment.API_URL + '/' + this.lang + environment.API.subscriptions + 'request/' + id + '/approve',
      {},
      {headers: headers}
    );
  }

  delete(id: number): Observable<any> {
    const headers = this.getHeaders();

    return this.http.delete<any>(
      environment.API_URL + '/' + this.lang + environment.API.subscriptions + id + '/',
      {headers: headers}
    );
  }

  updateManagerCard(subsrciptionId, source): Observable<Subscription> {

    const headers = this.getHeaders();
    return this.http.patch<Subscription>(
      environment.API_URL + '/' + this.lang + environment.API.subscriptions + subsrciptionId + '/change_card/',
      {source},
      {headers: headers}
    );
  }

  update(subsrciptionId, data): Observable<Subscription> {

    const headers = this.getHeaders();
    return this.http.patch<Subscription>(
      environment.API_URL + '/' + this.lang + environment.API.subscriptions + subsrciptionId + '/',
      data,
      {headers: headers}
    );
  }

  createRequestOfSubscription(userToken, requestSubscription: IRequestSubscription): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Token ${userToken}`
    });
    return this.http.post(
      `${environment.API_URL}/${this.lang}${environment.API.subscriptions}request/`,
      requestSubscription,
      { headers: headers });
  }
}
