<blitz-step-bar [stepDirector]="['blue', '', '', '', '']"></blitz-step-bar>
<section class="user-form" *ngIf="userForm">
  <form [formGroup]="userForm" novalidate (ngSubmit)="onSubmit()">
    <div class="fields-row">
      <div class="form-group">
        <label class="center-block" for="last_name">{{ 'Subscription 1.Last Name' | translate:lang }} <span class=red-asterisk>*</span></label>
        <input class="form-control text-input" formControlName="last_name" id="last_name">
        <div class="alert alert-danger" *ngIf="userForm.get('last_name').touched && userForm.get('last_name').hasError('required')">{{ 'Subscription 1.Last Name' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('last_name').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['last_name'] }}</div>
      </div>
      <div class="form-group">
        <label class="center-block" for="firstName">{{ 'Subscription 1.First Name' | translate:lang }} <span class=red-asterisk>*</span></label>
        <input class="form-control text-input" formControlName="first_name" id="firstName">
        <div *ngIf="userForm.get('first_name').touched && userForm.get('first_name').hasError('required')" class="alert alert-danger">{{ 'Subscription 1.First Name' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('first_name').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['first_name'] }}</div>
      </div>
    </div>
    <div class="fields-row">
      <div class="form-group">
        <label class="center-block" for="email">{{ 'Subscription 1.Email' | translate:lang }} <span class=red-asterisk>*</span></label>
        <input class="form-control text-input" formControlName="email" id="email">
        <div *ngIf="userForm.get('email').touched && userForm.get('email').hasError('required')" class="alert alert-danger">{{ 'Subscription 1.Email' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('email').touched && userForm.get('email').hasError('email') && !userForm.get('email').hasError('required')" class="alert alert-danger">{{ 'Subscription 1.Not Email' | translate:lang }}</div>
        <div *ngIf="userForm.get('email').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['email'] }}</div>
      </div>
      <div class="form-group">
        <label class="center-block" for="emailConfirm">{{ 'Subscription 1.Email Confirmation' | translate:lang }} <span class=red-asterisk>*</span></label>
        <input class="form-control text-input" formControlName="email_confirm" id="emailConfirm">
        <div *ngIf="userForm.get('email_confirm').touched && userForm.get('email_confirm').hasError('required')" class="alert alert-danger">{{ 'Subscription 1.Email Confirmation Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('emailEquality').hasError('notEquivalent')" class="alert alert-danger">{{ 'Subscription 1.Email Equivalence' | translate:lang }}</div>
        <div *ngIf="userForm.get('email_confirm').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['email_confirm'] }}</div>
      </div>
    </div>
    <div class="fields-row">
      <div class="form-group">
        <label class="center-block" for="password">{{ 'Subscription 1.Password' | translate:lang }} <span class=red-asterisk>*</span></label>
        <input class="form-control text-input" formControlName="password" id="password" type="password">
        <div *ngIf="userForm.get('password').touched && userForm.get('password').hasError('required')" class="alert alert-danger">{{ 'Subscription 1.Password' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('password').touched && userForm.get('password').hasError('minlength')" class="alert alert-danger">{{ 'Subscription 1.Password Min' | translate:lang }}</div>
        <div *ngIf="userForm.get('password').touched && userForm.get('password').hasError('weakPassword')" class="alert alert-danger">{{ 'Subscription 1.Password Weak' | translate:lang }}</div>
        <div *ngIf="userForm.get('password').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['password'] }}</div>
      </div>
      <div class="form-group">
        <label class="center-block" for="password_confirm">{{ 'Subscription 1.Password Confirmation' | translate:lang }} <span class=red-asterisk>*</span></label>
        <input class="form-control text-input" formControlName="password_confirm" id="password_confirm" type="password">
        <div *ngIf="userForm.get('password_confirm').touched && userForm.get('password_confirm').hasError('required')" class="alert alert-danger">{{ 'Subscription 1.Password Confirmation' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('password_confirm').touched && userForm.get('password_confirm').hasError('minlength')" class="alert alert-danger">{{ 'Subscription 1.Password Min' | translate:lang }}</div>
        <div *ngIf="userForm.get('password_confirm').touched && userForm.get('password_confirm').hasError('weakPassword')" class="alert alert-danger">{{ 'Subscription 1.Password Weak' | translate:lang }}</div>
        <div *ngIf="userForm.get('password_confirm').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['password_confirm'] }}</div>
        <div *ngIf="userForm.get('passwordEquality').hasError('notEquivalent')" class="alert alert-danger">{{ 'Subscription 1.Password Equivalence' | translate:lang }}</div>
      </div>
    </div>
    <div class="fields-row">
      <div class="form-group styled-select">
        <label class="center-block" for="civility">{{ 'Subscription 1.Civility' | translate:lang }} <span class=red-asterisk>*</span></label>
        <mdl-select #civilityEl [(ngModel)]="selectedCivility" id="civility" name="civility" formControlName="civility" class="mdl-select form-control" [label]="'Subscription 1.Civility Placeholder' | translate:lang" (click)="markTouched($event)">
            <mdl-option *ngFor="let civility of civilityList" [value]="civility['value']">{{ civility['name']}}</mdl-option>
        </mdl-select>
        <div *ngIf="userForm.get('civility').touched && userForm.get('civility').hasError('required')" class="alert alert-danger">{{ 'Subscription 1.Civility' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('civility').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['civility'] }}</div>
      </div>
      <div class="form-group">
        <label class="center-block" for="address">{{ 'Subscription 1.Address' | translate:lang }} <span class=red-asterisk>*</span></label>
        <input class="form-control text-input" formControlName="address" id="address">
        <div *ngIf="userForm.get('address').touched && userForm.get('address').hasError('required')" class="alert alert-danger">{{ 'Subscription 1.Address' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('address').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['address'] }}</div>
      </div>
    </div>
    <div class="fields-row">
      <div class="form-group styled-select">
        <label class="center-block" for="country">{{ 'Subscription 1.Country' | translate:lang }} <span class=red-asterisk>*</span></label>
        <mdl-select #countryEl [(ngModel)]="selectedCountry" id="country" name="country" formControlName="country" class="mdl-select form-control" [autocomplete]="true" [label]="'Subscription 1.Country Placeholder' | translate:lang" (inputChange)="onAutocompleteCountriesChange($event)" (change)="onCountryChanged()" (click)="markTouched($event)">
            <mdl-option *ngFor="let country of autoSelectCountries" [value]="country">{{ country['title'] }}</mdl-option>
        </mdl-select>
        <div *ngIf="userForm.get('country').touched && userForm.get('country').hasError('required')" class="alert alert-danger">{{ 'Subscription 1.Country' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('country').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['country'] }}</div>
      </div>
      <div class="form-group styled-select">
        <label class="center-block" for="region">{{ 'Subscription 1.Province' | translate:lang }} <span class=red-asterisk>*</span></label>
        <mdl-select #provinceEl [(ngModel)]="selectedProvince" id="region" name="region" formControlName="region" class="mdl-select form-control" [autocomplete]="true" [label]="getProvinceLabel()" (inputChange)="onAutocompleteProvincesChange($event)" (change)="onProvinceChanged()" (click)="markTouched($event)">
            <mdl-option *ngFor="let region of autoSelectProvinces" [value]="region">{{ region['title'] }}</mdl-option>
        </mdl-select>
        <div *ngIf="userForm.get('region').touched && userForm.get('region').hasError('required')" class="alert alert-danger">{{ 'Subscription 1.Province Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('region').touched && userForm.get('region').hasError('noCountry')" class="alert alert-danger">{{ 'Subscription 1.Country' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('region').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['region'] }}</div>
      </div>
    </div>
    <div class="fields-row">
      <div class="form-group styled-select">
        <label class="center-block" for="city">{{ 'Subscription 1.City' | translate:lang }} <span class=red-asterisk>*</span></label>
        <div class="input-wrapper"
             (document:click)="clickOutsideOfFilter(popover_cities, $event, userForm.get('city'), selectedCity)">
          <input type="text"
                 id="city"
                 name="city"
                 formControlName="city"
                 class="text-input"
                 [placeholder]="getCityLabel() | translate:lang"
                 [class.disabled]="!selectedProvince?.id"
                 (click)="popover_cities.toggle($event);"
                 (touchend)="popover_cities.toggle($event);">
          <blitz-popover #popover_cities class="blitz-popover"
                         blitzScroll bsFilterName="cities">
            <ul class="mdl-list">
              <li *ngFor="let city of autoSelectCities?.results"
                  (click)="onCityChanged(city); popover_cities.hide($event)">{{ city.title }}</li>
            </ul>
          </blitz-popover>
        </div>
        <div *ngIf="userForm.get('city').touched && userForm.get('city').hasError('required')" class="alert alert-danger">{{ 'Subscription 1.City' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('city').touched && userForm.get('city').hasError('noCountryOrProvince')" class="alert alert-danger">{{ 'Subscription 1.No Country Or Province' | translate:lang }}</div>
        <div *ngIf="userForm.get('city').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['city'] }}</div>
      </div>
      <div class="form-group">
        <label class="center-block" for="postCode">{{ 'Subscription 1.Post Code' | translate:lang }} <span class=red-asterisk>*</span></label>
        <input class="form-control text-input" formControlName="postcode" id="postCode">
        <div *ngIf="userForm.get('postcode').touched && userForm.get('postcode').hasError('required')" class="alert alert-danger">{{ 'Subscription 1.Post Code' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('postcode').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['postcode'] }}</div>
      </div>
    </div>
    <div class="fields-row">
      <div class="form-group">
        <label class="center-block" for="phone">{{ 'Subscription 1.Phone' | translate:lang }} <span class=red-asterisk>*</span></label>
        <input class="form-control text-input" formControlName="phone" id="phone">
        <div *ngIf="userForm.get('phone').touched && userForm.get('phone').hasError('required')" class="alert alert-danger">{{ 'Subscription 1.Phone' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('phone').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['phone'] }}</div>
      </div>
      <div class="form-group">
        <label class="center-block" for="company">{{ 'Subscription 1.Company Name' | translate:lang }} <span class=red-asterisk>*</span></label>
        <input class="form-control text-input" formControlName="company" id="company">
        <div *ngIf="userForm.get('company').touched && userForm.get('company').hasError('required')" class="alert alert-danger">{{ 'Subscription 1.Company Name' | translate:lang }} {{ 'Subscription 1.Required' | translate:lang }}</div>
        <div *ngIf="userForm.get('company').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['company'] }}</div>
      </div>
    </div>
    <div class="fields-row">
      <div class="form-group">
        <label class="center-block" for="accounting_email">{{ 'Subscription 1.Accounting Email' | translate:lang }} <span class=red-asterisk>*</span></label>
        <input class="form-control text-input" formControlName="accounting_email" id="accounting_email">
        <div *ngIf="userForm.get('accounting_email').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['accounting_email'] }}</div>
      </div>
      <div class="form-group">
        <p>
          Ce courriel sera utilisé pour l'envoi des factures, des avis de renouvellement ou pour toute autre informations concernant la facturation.
        </p>
      </div>
    </div>
    <div class="fields-row textarea-field">
      <div class="form-group textarea-field-group">
        <label class="center-block" for="comment">{{ 'Subscription 1.Comment' | translate:lang }}</label>
        <textarea class="form-control text-input" formControlName="comment" id="comment" type="text"></textarea>
        <div *ngIf="userForm.get('comment').hasError('outerError')" class="alert alert-danger">{{ subscriptionFormService.outerErrors['comment'] }}</div>
      </div>
    </div>
    <div class="continue-btn">
      <button class="common-btn blue" [class.disabled]="userForm.pristine || userForm.invalid" [disabled]="userForm.pristine || userForm.invalid">{{ 'Subscription 1.Continue' | translate:lang }}</button>
    </div>
  </form>
</section>
