<blitz-top [title]="title" [banner]="banner"></blitz-top>
<section class="contacts-page runway">
  <div class="runway-wrapper">
    <div class="contacts-page-wrapper">
      <div class="section-title">{{ 'Contact.Contact Section Title' | translate:lang }}</div>
      <div class="contacts">
        <div class="top-block">
          <div class="telephones text-wrapper">
            <div class="text-title">{{ 'Contact.Telephone Title' | translate:lang }}</div>
            <div class="plain-text">
              <div>{{ 'Contact.Telephone Line 1' | translate:lang }}</div>
              <div>{{ 'Contact.Telephone Line 2' | translate:lang }}</div>
            </div>
          </div>
          <div class="address text-wrapper">
            <div class="text-title">{{ 'Contact.Address Title' | translate:lang }}</div>
            <div class="plain-text">
              <div>{{ 'Contact.Address Line 1' | translate:lang }}</div>
              <div>{{ 'Contact.Address Line 2' | translate:lang }}</div>
            </div>
          </div>
        </div>
        <div class="bottom-block">
          <div class="related-employees text-wrapper">
            <div class="text-title">{{ 'Contact.Employees Title' | translate:lang }}</div>
            <div class="plain-text">
              <div>
                <span>{{ 'Contact.Employees Line 1 Occupation' | translate:lang }}</span>
                <a href="mailto:gisele.tourscher@datablitz.com">{{ 'Contact.Employees Line 1 Name' | translate:lang }}</a>
              </div>
              <div>
                <span>{{ 'Contact.Employees Line 2 Occupation' | translate:lang }}</span>
                <a href="mailto:miseajour2@datablitz.com">{{ 'Contact.Employees Line 2 Name' | translate:lang }}</a>
              </div>
              <div>
                <span>{{ 'Contact.Employees Line 3 Occupation' | translate:lang }}</span>
                <a href="mailto:finance@datablitz.com">{{ 'Contact.Employees Line 3 Name' | translate:lang }}</a>
              </div>
            </div>
          </div>
          <div class="emails text-wrapper">
            <div class="text-title">{{ 'Contact.Email Title' | translate:lang }}</div>
            <div class="plain-text">
              <a href="mailto:{{ email }}">{{ email }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
