import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'blitz-admin-subscription-create',
  templateUrl: './admin-subscription-create.component.html',
  styleUrls: ['./admin-subscription-create.component.scss']
})
export class AdminSubscriptionCreateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
