<blitz-top [title]="title" [banner]="banner"></blitz-top>
<section class="page about-page runway">
  <div class="runway-wrapper">
    <div class="text-with-big-image">
      <img src="../../assets/img/about-big-image.jpg">
      <div class="text-block">
        <div class="text-wrapper">
          <div class="text-title">{{ 'About.Text With Big Image Title' | translate:lang }}</div>
          <div class="plain-text">
            <div>{{ 'About.Text With Big Image Text Paragraph 1' | translate:lang }}</div>
            <div class="paragraph">{{ 'About.Text With Big Image Text Paragraph 2' | translate:lang }}</div>
            <div class="paragraph">{{ 'About.Text With Big Image Text Paragraph 3' | translate:lang }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="leftovers">
      <div class="text-wrapper">
        <div class="plain-text">
          <div class="paragraph">{{ 'About.Text With Big Image Text Paragraph 4' | translate:lang }}</div>
          <div class="paragraph">{{ 'About.Text With Big Image Text Paragraph 5' | translate:lang }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="team-block">
    <div class="runway-wrapper">
      <div class="text-wrapper">
        <div class="text-title">{{ 'About.Team Title' | translate:lang }}</div>
        <div class="plain-text">
          <div>{{ 'About.Team Text Paragraph 1' | translate:lang }}</div>
          <div class="paragraph">{{ 'About.Team Text Paragraph 2' | translate:lang }}</div>
          <div>{{ 'About.Team Text Paragraph 3' | translate:lang }}</div>
          <div>{{ 'About.Team Text Paragraph 4' | translate:lang }}</div>
        </div>
      </div>
    </div>
  </div>
</section>
