import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';

import { environment } from '../../environments/environment';
import { DefaultService } from './default.service';

export class Country {
  id: number;
  title: string;
}

export class Province {
  id: number;
  title: string;
  country: Country;
}

export class City {
  id: number;
  title: string;
  region: Province;
}

@Injectable()
export class CitiesService extends DefaultService {
  private headers: HttpHeaders;

  constructor(private http: HttpClient, public cookieService: CookieService) {
    super(cookieService);
    this.headers = new HttpHeaders({ 'Accept': 'application/json' });
  }

  getCountries(): Observable<any> {
    return this.http.get(
      `${environment.API_URL}/${this.lang}/api/v1/cities/countries`,
      {headers: this.headers}
    );
  }

  getProvinces(country_id: number): Observable<any> {
    let params = new HttpParams();
    params = params.set('country', country_id.toString());

    return this.http.get(
      `${environment.API_URL}/${this.lang}/api/v1/cities/regions`,
      { headers: this.headers, params: params }
    );
  }

  getCities(province_id: number, limit?: number): Observable<any> {
    let params = new HttpParams();
    params = params.set('region', province_id.toString());
    if (limit) {
      params = params.set('limit', limit.toString());
    }
    return this.http.get(
      `${environment.API_URL}/${this.lang}/api/v1/cities/cities`,
      { headers: this.headers, params: params }
    );
  }

  protected extractData(name: string, res: Response) {
    try {
      if (name) {
        sessionStorage.setItem(name, res['_body']);
      }
      return res;
    } catch (err) {
      return res.text();
    }
  }
}
