import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'blitz-admin-nav',
  templateUrl: './admin-nav.component.html',
  styleUrls: ['./admin-nav.component.scss']
})
export class AdminNavComponent implements OnInit {

  nav = [
    {
      label: 'Tableau de bord',
      icon: 'fas fa-chart-bar',
      routerLink: '/admin',
    },
    {
      label: 'Abonnements',
      icon: 'fas fa-id-card',
      routerLink: '/admin/subscription',
    },
    {
      label: 'Retour au site',
      icon: 'fas fa-sign-out-alt',
      type: 'action',
      routerLink: '/',
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
