
import {takeUntil} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Language, TranslationService } from 'angular-l10n';

import { PasswordResetService } from './password-reset.service';
import { SharedDataService } from '../shared';


@Component({
  selector: 'blitz-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  providers: [PasswordResetService]
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  @Language() lang: string;
  title: string;
  banner: string;
  passwordResetForm: UntypedFormGroup;
  private submitData: { [key: string]: string } = {};
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(public translation: TranslationService,
              public sharedDataService: SharedDataService,
              public activatedRoute: ActivatedRoute,
              public router: Router,
              public passwordResetService: PasswordResetService) {
    this.title = this.translation.translate('Password Reset.Top Title');
    this.banner = '../..//img/about-header.jpg';
    this.passwordResetForm = this.passwordResetService.passwordResetForm;
  }

  ngOnInit() {
    this.activatedRoute.paramMap.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((params: any) => {
        if (params && params.params && params.params.uid && params.params.token) {
          this.submitData.uid = params.params.uid;
          this.submitData.token = params.params.token;
        } else {
          this.router.navigate(['**']);
          return;
        }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubmit() {
    this.submitData.new_password1 = this.passwordResetForm.value.new_password1;
    this.submitData.new_password2 = this.passwordResetForm.value.new_password2;
    this.passwordResetService.resetPassword(this.submitData)
      .then((resolved) => this.handleSubmitSuccess(resolved))
      .catch((rejected) => this.handleSubmitError(rejected));
  }

  private handleSubmitSuccess(resolved) {
    this.sharedDataService.setMessageHandel({ message: 'Password Reset.Success Message' });
    this.router.navigateByUrl('/login');
  }

  private handleSubmitError(rejected) {
    if (rejected?.error?.token) {
      return this.sharedDataService
        .setMessageHandel({ message: 'Ce lien n\'est plus valide, veuillez faire une nouvelle demande pour \'mot de passe oublié\'.',
          status: 'error' });
    }

    let errors = rejected.json();
    if (errors.uid) {
      this.passwordResetService.outerErrors.uid = 'Password Reset.Invalid UID';
      this.passwordResetForm.get('uid').setErrors({ outerError: true });
    }
    if (errors.token) {
      this.passwordResetService.outerErrors.token = 'Password Reset.Invalid Token';
      this.passwordResetForm.get('token').setErrors({ outerError: true });
    }
    if (errors.new_password1) {
      this.passwordResetService.outerErrors.new_password1 = errors.new_password1.join(' ');
      this.passwordResetForm.get('new_password1').setErrors({ outerError: true });
    }
    if (errors.new_password2) {
      this.passwordResetService.outerErrors.new_password2 = errors.new_password2.join(' ');
      this.passwordResetForm.get('new_password2').setErrors({ outerError: true });
    }
  }

}
