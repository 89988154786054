import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SubscriptionFormValuesService } from './subscription-form-values.service';

import { environment } from '../../environments/environment';
import { DefaultService } from '../shared';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class RegistrationService extends DefaultService {
  constructor(private http: HttpClient,
              private subscriptionFormValuesService: SubscriptionFormValuesService,
              public cookieService: CookieService) {
    super(cookieService);
  }

  submitValues(): Promise<any> {
    let body = this.subscriptionFormValuesService.formalizedValues();
    body['country'] = body['country'].id;
    body['region'] = body['region'].id;
    body['city'] = body['city'].id;
    body['addons'] = JSON.stringify(this.subscriptionFormValuesService.value['addons']);
    return this.http
      .post(`${environment.API_URL}/${this.lang}/api/v1/users/accounts/registration/`,
        body,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .toPromise();
  }

  activateAccount(activationToken): Promise<any> {
    let body = {};
    body['activation_token'] = activationToken;
    return this.http
      .post(`${environment.API_URL}/${this.lang}/api/v1/users/accounts/activation/`,
        body,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .toPromise();
  }

  getPrices(userToken): Promise<any> {
    return this.http.get(
      `${environment.API_URL}/${this.lang}/api/v1/subscriptions/priceplans/`,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Token ${userToken}`,
          }
        )
      }
    ).toPromise();
  }

  getTaxes(regionId): Promise<any> {
    return this.http
      .get(`${environment.API_URL}/${this.lang}/api/v1/payments/taxes/${regionId}/`,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .toPromise();
  }

  sendPayment(userToken, data): Promise<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Token ${userToken}`
    });
    return this.http.post(`${environment.API_URL}/${this.lang}/api/v1/subscriptions/buy/`, data, { headers: headers })
                    .toPromise();
  }


  updateSubscription(userToken, subscriptionId, autoRenew): Promise<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Token ${userToken}`
    });
    return this.http.patch(
      `${environment.API_URL}/${this.lang}/api/v1/subscriptions/${subscriptionId}/`,
      {
        auto_renew: autoRenew,
      },
      { headers: headers }
    )
    .toPromise();
  }

  updateNewSubscription(userToken, data): Promise<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Token ${userToken}`
    });
    return this.http.patch(
      `${environment.API_URL}/${this.lang}/api/v1/subscriptions/request/${data.id}`,
      data,
      { headers: headers }
    )
      .toPromise();
  }
}
