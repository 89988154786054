<div class="subscription-list">
  <div class="subscription-list__filters">
    <div class="subscription-list__filters__item subscription-list__filters__item--searchbar">
      <label>
      Rechercher un administrateur par nom, prénom ou adresse courriel:
      </label>
      <input type="text" name="test" class="input input--full" (keypress)="changeSearch()" [(ngModel)]="searchFilter"/>
    </div>
    <div class="subscription-list__filters__item">
      <label for="payment__completed">Paiement:</label>
      <select class="select" id="payment__completed" name="payment__completed" [(ngModel)]="paymentCompletedFilter" (change)="refreshSubscriptions()">
        <option value="false">Non payé</option>
        <option value="true">Payé</option>
        <option value="all">Tous</option>
      </select>
    </div>
    <div class="subscription-list__filters__item">
      <label for="auto_renew">Auto-renouvellement:</label>
      <select class="select" id="auto_renew" name="auto_renew" [(ngModel)]="autoRenewFilter" (change)="refreshSubscriptions()">
        <option value="true">Oui</option>
        <option value="false">Non</option>
        <option value="all">Tous</option>
      </select>
    </div>
    <div class="subscription-list__filters__item">
      <label for="is_approved">Status d'approbation:</label>
      <select class="select" id="is_approved" name="is_approved" [(ngModel)]="approvalFilter" (change)="refreshSubscriptions()">
        <option value="approved">Approuvées</option>
        <option value="pending">En attente</option>
        <option value="all">Toutes</option>
      </select>
    </div>
  </div>
  <blitz-my-table [data]="data" [settings]="settings" (changePage)="changePage($event)" (selectItem)="goToDetail($event)"></blitz-my-table>
</div>
