import { Addon } from './addon';

export class Member {
  'id': number;
  'email': string;
  'first_name': string;
  'last_name': string;
  'phone': string;
  'country': number | null;
  'region': number | null;
  'city': number | null;
  'company': string;
  'function': string;
  'is_manager': boolean;
  'is_active': boolean;
  'addon': Addon[];
  'has_active_mm': boolean;
}
