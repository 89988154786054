import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'blitzFilter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, field: string, lang = 'fr'): any[] {
    let options = { usage: 'search', sensitivity: 'base' };
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    return items.filter(it => {
      return !it[field].substring(0, searchText.length).localeCompare(searchText, lang, options);
    });
  }
}
