import { CurrentUser } from '../model/current-user';
import { Profile } from '../model/profile';
import { Subscription } from '../model/subscription';
import { PricePlan } from '../model/price-plan';
import { Addon, IAddonPricePlan } from '../model/addon';
import { Member } from '../model/member';
import { Payment } from '../model/payment';

export const stubPricePlan: any = {
  id: 100,
  title: 'stub_title',
  price: 300,
  price_currency: 'CAD',
  default_users_slots: 2,
  stripe_id: 'stub_corp or stub_addon',
};

export const stubCatalogue: any = {
  id: 100,
  title: 'stub_catalogue',
  price: 300,
  price_currency: 'CAD',
  effective_date: '2019-04-14T18:55:12.026215-04:00',
  end_effective_date: '2079-04-14T18:55:12.026215-04:00',
  new_customer_only: false,
  plan: stubPricePlan,
};

export const stubAddons: Addon[] = [
  {
    'id': 7,
    'manager': 6,
    'plan': stubPricePlan,
    'amount': 1,
    'is_active': true,
    'stripe_id': 'mm_search',
    'stripe_item_id': '',
  },
  {
    'id': 8,
    'manager': 6,
    'plan': stubPricePlan,
    'amount': 3,
    'is_active': true,
    'stripe_id': 'additional_user',
    'stripe_item_id': '',
  }
];

export const stubMembers: Member[] = [
  {
    'id': 12,
    'email': 'stub_member_email',
    'first_name': 'stub_member_first_name',
    'last_name': 'stub_member_last_name',
    'phone': 'stub_member_phone',
    'country': 9,
    'region': 10,
    'city': 11,
    'company': 'stub_member_company',
    'function': 'stub_member_function',
    'is_manager': false,
    'is_active': true,
    'addon': stubAddons,
    'has_active_mm': true,
  }
];

export const stubPayment: Payment = {
  subscription: 1124,
  price: '0.00',
  description: '',
  completed: true,
  created: '2019-04-14T18:55:12.026215-04:00',
  modified: '2019-04-14T18:55:53.016458-04:00',
  is_pending: false,
  id: 657,
  days_before_due : 0,
  card_is_charged: false,
};

export const stubSubscription: Subscription = {
  id: 5,
  manager: 6,
  catalogue: stubCatalogue,
  is_active: true,
  payment: stubPayment,
  auto_renew: true,
  addons: [],
  payment_type: 'cc'
};

export const stubProfile: Profile = {
  'id': 1,
  'email': 'stub@redwerk.com',
  'accounting_email': 'stub@redwerk.com',
  'accounting_first_name': 'John',
  'accounting_last_name': 'Doe',
  'first_name': 'stub_first_name',
  'last_name': 'stub_last_name',
  'civility': 'stub_civility',
  'address': 'stub_address',
  'postcode': 'stub_postcode',
  'phone': 'stub_phone',
  'company': 'stub_company',
  'function': 'stub_function',
  'comment': 'stub_comment',
  'tos_agreement': true,
  'is_manager': true,
  'newsletter_agreement': true,
  'country': { id: 2, name: 'stub_country' },
  'city': { id: 3, name: 'stub_city' },
  'region': { id: 4, name: 'stub_region' },
  'subscriptions': stubSubscription,
  'addons': stubAddons,
  'has_active_mm': true,
  'members': stubMembers,
  'is_superuser': false,
  'permissions': [],
};

export const currentUserStub: CurrentUser = {
  email: 'stub@redwerk.com',
  token: 'stub_token',
  data: stubProfile
};
