export const environment = {
  TITLE: 'Club de Presse Blitz',
  INFO_EMAIL: 'info@datablitz.com',
  API_URL: 'https://datablitz-api-dev.fjnr.ca',
  STRIPE_PK: 'pk_test_7ZcS7MvvBo0QaJKoU9QWUrRH',
  API: {
    subscriptions: '/api/v1/subscriptions/',
    payments: '/en/api/v1/payments/'
  },
  SEARCH_TYPES: [
    {
      name: 'media',
      text_en: 'Media',
      text_fr: 'Média'
    },
    {
      name: 'services',
      text_en: 'Services',
      text_fr: 'Émission'
    },
    {
      name: 'contacts',
      text_en: 'Contacts',
      text_fr: 'Contact'
    }
  ],
  production: true,
  default_language: 'fr',
};
