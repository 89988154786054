<div class="page-top">
  <img src="{{ banner }}" alt="" class="page-top-img">
  <div class="page-top-caption--wrapper">
    <div class="page-top-caption">
      <div class="page-top-caption-content--wrapper">
        <h1 class="page-top-caption-title">{{ title | translate:lang }}</h1>
        <p *ngIf="subtitleTop" class="page-top-caption-text">{{ subtitleTop | translate:lang }}</p>


        <blitz-popover class="blitz-popover" #popover mdl-shadow="24">
          <button class="blitz-popover__close" (click)="popover.toggle($event)">
            &#10005;
          </button>
          <div>
            <div>{{ 'Homepage.Demo Message' | translate:lang }}</div>
            <div>
              <a href="mailto:{{ email }}">{{ 'Homepage.Demo Email Link' | translate:lang }}{{ email }}</a>
            </div>
            <div>Tél.: 1 888 997-1801</div>
          </div>
        </blitz-popover>

        <div *ngIf="buttons" class="buttons">
          <div class="demo-btn">
            <button type="button" class="common-btn big" (click)="popover.toggle($event)">
              {{ 'Homepage.Demo Button' | translate:lang }}
            </button>
          </div>
          <div class="subscribe-btn">
            <button type="button" [routerLink]="['subscribe/step-1']" class="common-btn big">{{ 'Homepage.Subscribe Button' | translate:lang }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
