import { Component, OnInit } from '@angular/core';

import { Language, TranslationService } from 'angular-l10n';

@Component({
  selector: 'blitz-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  @Language() lang: string;
  title: string;
  banner: string;

  constructor(private translation: TranslationService) {
    this.title = this.translation.translate('About.Top Title');
    this.banner = '../../assets//img/about-header.jpg';
  }

  ngOnInit() {}

}
