import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../environments/environment';

@Injectable()
export class DefaultService {
  lang: string;

  constructor(public cookieService: CookieService) {
    if (this.cookieService.get('defaultLocale')) {
      this.lang = this.cookieService.get('defaultLocale').split('-')[0];
    } else {
      this.lang = environment.default_language;
    }
  }
}
