import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class HeaderService {
  public showLoginModal = new Subject<boolean>();

  constructor() {
  }

  public setShowLoginModal(content) {
    this.showLoginModal.next(content);
  }

  public getShowLoginModal(): Observable<any> {
    return this.showLoginModal.asObservable();
  }
}
