import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DefaultService } from '../../../shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ProvincesService extends DefaultService {


  token: string;

  private headers: HttpHeaders;

  constructor(private http: HttpClient,
              public cookieService: CookieService) {
    super(cookieService);
    this.setHeaders();
  }

  private setHeaders() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;

    this.headers = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': `Token ${this.token}`
    });
  }

  list(): Observable<any> {

    return this.http.get(
      `${environment.API_URL}/${this.lang}/api/v1/influencers/province/`,
      {headers: this.headers}
    );
  }

}
