<blitz-step-bar [stepDirector]="['blue', 'blue', '', '', '']"></blitz-step-bar>
<section class="user-confirmation">
  <div class="user-confirmation-row">
    <div class="field-info-group">
      <div class="field-title">{{ 'Subscription 2.Last Name' | translate:lang }}</div>
      <div class="field-info">{{ formValues['last_name'] || '---'}}</div>
    </div>
    <div class="field-info-group">
      <div class="field-title">{{ 'Subscription 2.First Name' | translate:lang }}</div>
      <div class="field-info">{{ formValues['first_name'] || '---'}}</div>
    </div>
  </div>
  <div class="user-confirmation-row">
    <div class="field-info-group">
      <div class="field-title">{{ 'Subscription 2.Email' | translate:lang }}</div>
      <div class="field-info">{{ formValues['email'] || '---'}}</div>
    </div>
    <div class="field-info-group">
      <div class="field-title">{{ 'Subscription 2.Phone' | translate:lang }}</div>
      <div class="field-info">{{ formValues['phone'] || '---'}}</div>
    </div>
  </div>
  <div class="user-confirmation-row">
    <div class="field-info-group civility-group">
      <div class="field-title">{{ 'Subscription 2.Civility' | translate:lang }}</div>
      <div class="field-info">{{ formValues['civility'] || '---'}}</div>
    </div>
    <div class="field-info-group">
      <div class="field-title">{{ 'Subscription 2.Address' | translate:lang }}</div>
      <div class="field-info">{{ formValues['address'] || '---'}}</div>
    </div>
  </div>
  <div class="user-confirmation-row">
    <div class="field-info-group">
      <div class="field-title">{{ 'Subscription 2.Country' | translate:lang }}</div>
      <div class="field-info">{{ formValues['country'] || '---'}}</div>
    </div>
    <div class="field-info-group">
      <div class="field-title">{{ 'Subscription 2.Province' | translate:lang }}</div>
      <div class="field-info">{{ formValues['region'] || '---'}}</div>
    </div>
  </div>
  <div class="user-confirmation-row">
    <div class="field-info-group">
      <div class="field-title">{{ 'Subscription 2.City' | translate:lang }}</div>
      <div class="field-info">{{ formValues['city'] || '---'}}</div>
    </div>
    <div class="field-info-group">
      <div class="field-title">{{ 'Subscription 2.Post Code' | translate:lang }}</div>
      <div class="field-info">{{ formValues['postcode'] || '---'}}</div>
    </div>
  </div>
  <div class="user-confirmation-row">
    <div class="field-info-group">
      <div class="field-title">{{ 'Subscription 2.Company Name' | translate:lang }}</div>
      <div class="field-info">{{ formValues['company'] || '---'}}</div>
    </div>
  </div>
  <div class="user-confirmation-row">
    <div class="field-info-group">
      <div class="field-title">{{ 'Subscription 2.Accounting Email' | translate:lang }}</div>
      <div class="field-info">{{ formValues['accounting_email'] || '---'}}</div>
    </div>
  </div>
  <div class="user-confirmation-row">
    <div class="field-info-group text-area">
      <div class="field-title">{{ 'Subscription 2.Comment' | translate:lang }}</div>
      <div class="field-info">{{ formValues['comment'] || '---'}}</div>
    </div>
  </div>
</section>
<blitz-buttons-bar [previousBtnUrl]="'/subscribe/step-1'" [continueBtnUrl]="'/subscribe/step-3'"></blitz-buttons-bar>
