<div class="alert">
<i class="alert__icon fas fa-exclamation-triangle" aria-hidden="true"></i>
<div class="alert__content">
    <div *ngFor="let message of messages">
      {{ message | translate }}
    </div>

    <ng-content></ng-content>
</div>
</div>
