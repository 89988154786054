import { Component, OnInit } from '@angular/core';

import { Language, TranslationService } from 'angular-l10n';

@Component({
  selector: 'blitz-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  @Language() lang: string;
  title: string;
  banner: string;

  constructor(private translation: TranslationService) {
    this.title = this.translation.translate('Not Found.Top Title');
    this.banner = '../../assets/img/services-top.jpg';
  }

  ngOnInit() {
  }

}
