import * as _ from 'underscore';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

import { SubscriptionStepsComponent } from './subscription-steps.component';
import { AuthService } from '../shared';

@Injectable()
export class NavigateOutsideFormGuard implements CanDeactivate<SubscriptionStepsComponent> {
  constructor(private authService: AuthService) {}

  canDeactivate(
    component: SubscriptionStepsComponent,
    route: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (component.subscriptionFormValuesService.isFormTouched
      && !nextState.url.includes('subscribe')
      && _.isEmpty(this.authService.getCurrentUser())) {
      let isDiscarded = confirm(component.translation.translate('Subscription.Discard Changes'));
      if (isDiscarded) {
        component.subscriptionFormValuesService.reset();
      }
      return isDiscarded;
    }
    return true;
  }
}
