import * as _ from 'underscore';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../shared';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(public router: Router, public authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.isLoggedIn(state.url);
  }

  protected isLoggedIn(url: string) {
    let currentUser = this.authService.getCurrentUser();
    if (!_.isEmpty(currentUser) && currentUser.data) {
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: url } });
      return false;
    }
  }
}