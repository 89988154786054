import { Component, OnInit } from '@angular/core';
import { Language, TranslationService } from 'angular-l10n';

import { SubscriptionFormValuesService } from './subscription-form-values.service';

@Component({
  selector: 'blitz-subscription-steps',
  templateUrl: './subscription-steps.component.html',
  styleUrls: ['./subscription-steps.component.scss']
})
export class SubscriptionStepsComponent implements OnInit {
  @Language() lang: string;
  title: string;
  banner: string;

  constructor(public translation: TranslationService, public subscriptionFormValuesService: SubscriptionFormValuesService) {
    this.title = this.translation.translate('Subscription.Top Title');
    this.banner = '../assets/img/about-header.jpg';
  }

  ngOnInit() {
  }
}
