import { filter, takeUntil } from 'rxjs/operators';
import * as _ from 'underscore';
import { Component, OnInit, ElementRef, Renderer2, ViewChild, HostBinding, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { Language } from 'angular-l10n';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

import { SlideMenuComponent } from './slide-menu/slide-menu.component';
import {
  CurrentUser,
  Debounce,
  AuthService,
  HeaderService,
  MenuListDto,
  SharedDataService,
  SearchService,
  DeviceService, FavoritesService
} from '../shared';



import { NotificationsService } from '../shared/notifications.service';
import { GoogleAnalyticsEventsService } from '../google-analytics-events.service';
import { ResponseApi } from '../shared/model/ResponseAPI';
import { INotification } from '../shared/model/notification';
import { IGroupFavorite } from '../shared/model/group-favorite';

@Component({
  selector: 'blitz-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public showModal: boolean;
  @ViewChild(SlideMenuComponent, {static: true}) private slideMenu: SlideMenuComponent;
  @Language() lang: string;
  title: string;
  showLogin = true;
  @HostBinding('class.is-member') isMember ? = false; // TODO: remove currentUser status mock
  backgroundColor ? = 'transparent-background';
  public menuList: Array<{ [key: string]: string }>;
  public hideSecondHeader: boolean;
  public notifications: INotification[] = [];
  public numberOfNotifications: number;
  private isTabletOrMobile: boolean;
  private activeMobileBackgroundColor ? = 'white-background';
  private scrolledBackgroundColor ? = 'white-background';
  private hamburgerBackgroundColor ? = 'white-background';
  private activeHamburgerBackgroundColor ? = 'black-background';
  private scrolledHamburgerBackgroundColor ? = 'black-background';
  private headerClass: string;
  private currentPath: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private menuListType: MenuListDto;
  private _user: CurrentUser;
  get user(): CurrentUser {
    return this._user;
  }

  set user(value: CurrentUser) {
    this._user = value;
    this.updateComponentFromUser(value);
  }


  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private headerService: HeaderService,
              private searchService: SearchService,
              private sharedDataService: SharedDataService,
              private deviceService: DeviceService,
              private router: Router,
              public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
              public authService: AuthService,
              private notificationService: NotificationsService,
              private _favoritesService: FavoritesService) {
    this.title = environment.TITLE;
    this.headerClass = this.backgroundColor;
    this.showModal = false;
    this.menuListType = this.sharedDataService.getMenuListTypes();
  }

  public ngOnInit() {
    this.refreshNotificationList();
    this.authService.getFullUserInfo().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((data: CurrentUser) => {
        this.user = data;
      });
    this.headerService.getShowLoginModal().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((data: boolean) => {
        this.showModal = data;
      });

    this.deviceService.device.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((device: string) => {
        this.isTabletOrMobile = (device === 'mobile' || device === 'tablet');
      });

    this.setSecondHeader(this.router.url);

    this.router.events.pipe(filter((data: RouterEvent) => {
      return data instanceof NavigationEnd;
    }),
      takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data: NavigationEnd) => {
        this.setSecondHeader(data.urlAfterRedirects);
      });
    this.scrollCheck();
  }

  setSecondHeader(currentUrl: string) {
    if (_.include([
      '/mz/dashboard',
      '/mz/search',
      '/mz/favorites',
      '/mz/ptites-vites',
      '/mz/accounts/my',
      '/mz/accounts/all',
      '/mz/accounts/subscription',
    ], currentUrl)) {
      this.hideSecondHeader = !this.isTabletOrMobile;
    } else if ((currentUrl === '/mz/influencers') || currentUrl.includes('/mz/favorite-list')) {
      this.hideSecondHeader = true;
    } else {
      this.hideSecondHeader = false;
    }
    this.currentPath = currentUrl;
    if (document.body.className === 'no-scroll') {
      this.renderer.removeClass(document.body, 'no-scroll');
    }
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private refreshNotificationList() {
    const user = this.authService.getCurrentUser();
    if (user.data) {
      this.notificationService.getUnreadNotifications().subscribe(
        (responseApi: ResponseApi<INotification>) => {
          this.notifications = [];
          const notifications = responseApi.results;
          this.numberOfNotifications = 0;
          for (const notification of notifications) {
            if (notification.state === 'U') {
              // Update number of total notifications
              this.numberOfNotifications += 1;
              // Limit number of notifications visible in the popover
              if (this.notifications.length < 1) {
                this.notifications.push(notification);
              }
            }
          }
        }
      );
    }
  }

  public visitNotification(notification: INotification) {
    this.notificationService.setNotificationAsRead(notification.id).subscribe(
      (notificationRead: INotification) => {
        this.refreshNotificationList();

        const notification_description = JSON.parse(notificationRead.description);
        if ( notification_description.favorite) {
          this._favoritesService.getFavoriteRequest(notification_description.favorite).subscribe(
            (groupFavorite: IGroupFavorite) => {
              this._favoritesService.goToSearch(groupFavorite);
            }
          );
        } else {
          this.router.navigate([notification.uri]);
        }

      }
    );
  }

  public markNotificationAsRead(notification: INotification) {
    this.notificationService.setNotificationAsRead(notification.id).subscribe(
      (resp: any) => {
        this.refreshNotificationList();
      }
    );
  }

  getNumberOfNotifications() {
    if (this.numberOfNotifications < 100) {
      return this.numberOfNotifications;
    } else {
      return '+';
    }
  }

  public get showMemberName(): boolean {
    if (!this.showLogin && !this.isTabletOrMobile) {
      return true;
    }
    if (!this.showLogin && this.isTabletOrMobile) {
      return !this.isMember || this.hideSecondHeader;
    }
  }

  public getUrl(item) {
    if (item.link) {
      return item.link;
    } else {
      return item.external_link;
    }
  }

  public onSearch(value: string) {
    value = value.trim();
    if (value && value.length > 2) {
      this.googleAnalyticsEventsService.emitEvent('onSearch', 'searchAction', 'Search', value);

      this.searchService.setQueryParams({
        text: value
      });
      if (this.currentPath !== '/mz/search') {
        this.router.navigateByUrl('/mz/search');
      }
    }
  }

  @Debounce(100)
  onScroll() {
    this.scrollCheck();
  }

  toggleHeader() {
    if (this.headerClass === this.backgroundColor) {
      this.setHeader(this.activeMobileBackgroundColor, this.activeHamburgerBackgroundColor);
    } else if (document.body.scrollTop < 50 && document.documentElement.scrollTop < 50) {
      this.setHeader(this.backgroundColor, this.hamburgerBackgroundColor);
    }
  }

  toggleLogin() {
    this.showModal = !this.showModal;
  }

  close() {
    this.showModal = false;
  }

  private setHeader(headerClass: string, hamburgerClass: string) {
    const $firstHeader = this.el.nativeElement.firstChild;
    this.renderer.removeAttribute($firstHeader, 'class');
    this.renderer.addClass($firstHeader, headerClass);
    this.headerClass = headerClass;
    this.setHamburger(hamburgerClass);
  }

  private setHamburger(hamburgerClass: string) {
    const $hamburgerWrapper = this.slideMenu.el.nativeElement.firstElementChild.firstElementChild;
    if ($hamburgerWrapper) {
      this.renderer.removeAttribute($hamburgerWrapper, 'class');
      this.renderer.addClass($hamburgerWrapper, hamburgerClass);
    }
  }

  private scrollCheck() {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      this.renderer.addClass(this.el.nativeElement, 'scrolled');
      this.setHeader(this.scrolledBackgroundColor, this.scrolledHamburgerBackgroundColor);
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'scrolled');
      this.setHeader(this.backgroundColor, this.hamburgerBackgroundColor);
    }
  }

  private updateComponentFromUser(value: CurrentUser) {
    if (value && value.email) {
      const user_permissions = this.authService.getPermissions();
      if (value.data && user_permissions && user_permissions.lastIndexOf('can_access_base') >= 0) {
        this.isMember = true;
        this.showLogin = false;
        this.menuList = _.reject(this.menuListType.logged, item => {
          if (item['id'] === 'media_match') {
            return user_permissions.lastIndexOf('can_access_mm') < 0;
          } else if (item['id'] === 'influencers') {
            return user_permissions.lastIndexOf('can_access_plik') < 0;
          }
        });
      } else {
        this.isMember = false;
        this.showLogin = false;
        this.menuList = this.menuListType.notLogged;
      }
      this.backgroundColor = 'black-background';
      this.activeMobileBackgroundColor = 'black-background';
      this.scrolledBackgroundColor = 'black-background';
      this.activeHamburgerBackgroundColor = 'white-background';
      this.scrolledHamburgerBackgroundColor = 'white-background';
    } else {
      this.showLogin = true;
      this.isMember = false;
      this.menuList = this.menuListType.notLogged;
      this.backgroundColor = 'transparent-background';
      this.activeMobileBackgroundColor = 'white-background';
      this.scrolledBackgroundColor = 'white-background';
      this.activeHamburgerBackgroundColor = 'black-background';
      this.scrolledHamburgerBackgroundColor = 'black-background';
    }
    this.scrollCheck();
  }
}
