import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollDirective } from './scroll.directive';
import { ClickOutsideDirective } from './click-outside.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ScrollDirective,
    ClickOutsideDirective
  ],
  exports: [
    ScrollDirective,
    ClickOutsideDirective
  ]
})
export class CommonDirectivesModule {

}
