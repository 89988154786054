import { Component, Input, OnInit } from '@angular/core';

import { Language } from 'angular-l10n';
import { environment } from '../../environments/environment';

@Component({
  selector: 'blitz-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss']
})
export class TopComponent implements OnInit {
  @Language() lang: string;
  @Input() title: string;
  @Input() banner: string;
  @Input() subtitleTop: string;
  @Input() buttons: string;
  email = environment.INFO_EMAIL;

  constructor() { }

  ngOnInit() {}

}
