import { Component, OnInit } from '@angular/core';
import { Language } from 'angular-l10n';

import { environment } from '../../environments/environment';

@Component({
  selector: 'blitz-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Language() lang: string;
  title: string;
  email = environment.INFO_EMAIL;
  phone = '1 888 997-1801';
  currentYear = 2018;

  linkToBlog = 'https://blog.datablitz.com';
  linkToFacebook = 'https://www.facebook.com/clubdepresseblitz/';
  linkToRightOfUse = '../../assets/docs/Blitz_-_droit_d_utilisation.pdf';
  linkToPersonalProtection = '../../assets/docs/Blitz_-_protection_des_renseignements_personnels.pdf';

  constructor() {
    this.title = environment.TITLE;
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }

}
