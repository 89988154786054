import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';

import { DefaultFormService } from '../../shared';

@Injectable()
export class LoginService extends DefaultFormService {
  blitzForm: UntypedFormGroup;
  blitzResetPassForm: UntypedFormGroup;

  constructor(protected fb: UntypedFormBuilder) {
    super();
    this.blitzForm = this.fb.group({
      email: new UntypedFormControl('', [this.NoWhitespaceValidator(), this.OuterValidator('email')]),
      password: new UntypedFormControl('', [this.NoWhitespaceValidator(), this.OuterValidator('password')]),
    });
    this.blitzResetPassForm = this.fb.group({
      email: new UntypedFormControl('', [this.NoWhitespaceValidator(), this.OuterValidator('email')])
    });
  }
}
