import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslationModule, TranslationService } from 'angular-l10n';
import { MdlModule } from '@angular-mdl/core';
import { MdlSelectModule } from '@angular-mdl/select';
import { MdlPopoverModule } from '@angular-mdl/popover';

import { CommonComponentsModule } from '../shared';

import { HeaderComponent } from './header.component';
import { SlideMenuComponent } from './slide-menu/slide-menu.component';
import { SecondHeaderComponent } from './second-header/second-header.component';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslationModule,
        MdlModule,
        MdlSelectModule,
        MdlPopoverModule,
        RouterModule,
        CommonComponentsModule,
    ],
  declarations: [
    HeaderComponent,
    SlideMenuComponent,
    SecondHeaderComponent,
    LoginComponent
  ],
  exports: [
    HeaderComponent,
    LoginComponent,
  ],
  providers: [
    LoginService,
    TranslationService
  ]
})
export class HeaderModule { }
