import { PricePlan } from './price-plan';
import { Payment } from './payment';
import { Manager } from './manager';
import { Addon } from './addon';
import {Catalogue} from './catalogue';

export class Subscription {
  id?: number;
  url?: string;
  manager?: number | Manager;
  catalogue: Catalogue;
  is_active: boolean;
  start?: string;
  end?: string;
  payment: Payment;
  is_approved?;
  auto_renew;
  addons: Addon[];
  payment_type;
  is_renew?: boolean;
  number_of_seats?: number;
}

export interface IRequestSubscription {
  payment_type: string;
  source: string;
  addons: string;
  subscription_price_plan?: string;
  is_subscription_renew: boolean;
  is_request_update_renew: boolean;
  origin_subscription: string;
}
