<div *ngIf="hasError()" class="validation">
  <div *ngIf="form.get(path).hasError('required') && !notDefaultFields.includes(path)"
       class="alert alert-danger">{{ 'MZ Edit Accounts.Required' | translate:lang }}</div>
  <div *ngIf="form.get(path).hasError('required') && path === 'password_old'"
       class="alert alert-danger">
    {{ 'Subscription 1.Password Old' | translate:lang }} {{ 'MZ Edit Accounts.Required' | translate:lang }}
  </div>
  <div *ngIf="form.get(path).hasError('required') && path === 'password_new'"
       class="alert alert-danger">
    {{ 'Subscription 1.Password' | translate:lang }} {{ 'MZ Edit Accounts.Required' | translate:lang }}
  </div>
  <div *ngIf="form.get(path).hasError('required') &&  path === 'password_confirm'"
       class="alert alert-danger">
    {{ 'Subscription 1.Password Confirmation' | translate:lang }} {{ 'MZ Edit Accounts.Required' | translate:lang }}
  </div>
  <div *ngIf="form.get(path).hasError('required') && path === 'email'"
       class="alert alert-danger">
    {{ 'Subscription 1.Email' | translate:lang }} {{ 'MZ Edit Accounts.Required' | translate:lang }}
  </div>
  <div *ngIf="form.get(path).hasError('required') && path === 'first_name'"
       class="alert alert-danger">
    {{ 'Subscription 1.First Name' | translate:lang }} {{ 'MZ Edit Accounts.Required' | translate:lang }}
  </div>
  <div *ngIf="form.get(path).hasError('required') && path === 'last_name'"
       class="alert alert-danger">
    {{ 'MZ Edit Accounts.Last Name' | translate:lang }} {{ 'MZ Edit Accounts.Required' | translate:lang }}
  </div>
  <div *ngIf="form.get(path).hasError('required') && path === 'postcode'"
       class="alert alert-danger">
    {{ 'MZ Edit Accounts.Post Code' | translate:lang }} {{ 'MZ Edit Accounts.Required' | translate:lang }}
  </div>
  <div *ngIf="form.get(path).hasError('required') && path === 'phone'"
       class="alert alert-danger">
    {{ 'Subscription 1.Phone' | translate:lang }} {{ 'MZ Edit Accounts.Required' | translate:lang }}
  </div>
  <div *ngIf="form.get(path).hasError('required') && path === 'address'"
       class="alert alert-danger">
    {{ 'Subscription 1.Address' | translate:lang }} {{ 'MZ Edit Accounts.Required' | translate:lang }}
  </div>
  <div *ngIf="form.get(path).hasError('required') && path === 'company'"
       class="alert alert-danger">
    {{ 'MZ Edit Accounts.Company Name' | translate:lang }} {{ 'MZ Edit Accounts.Required' | translate:lang }}
  </div>
  <div *ngIf="form.get(path).hasError('required') && path === 'country'"
       class="alert alert-danger">
    {{ 'Subscription 1.Country' | translate:lang }} {{ 'MZ Edit Accounts.Required' | translate:lang }}
  </div>
  <div *ngIf="form.get(path).hasError('required') && path === 'region'"
       class="alert alert-danger">
    {{ 'Subscription 1.Province' | translate:lang }} {{ 'MZ Edit Accounts.Required' | translate:lang }}
  </div>
  <div *ngIf="form.get(path).hasError('required') && path === 'city'"
       class="alert alert-danger">
    {{ 'Subscription 1.City' | translate:lang }} {{ 'MZ Edit Accounts.Required' | translate:lang }}
  </div>
  <div *ngIf="form.get(path).hasError('required') && path === 'accounting_email'"
       class="alert alert-danger">
    {{ 'MZ Edit Accounts.Accounting Email' | translate:lang }} {{ 'MZ Edit Accounts.Required' | translate:lang }}
  </div>
  <div *ngIf="form.get(path).hasError('noCountryOrProvince')" class="alert alert-danger">{{ 'Subscription 1.No Country Or Province' | translate:lang }}</div>
  <div *ngIf="form.get(path).hasError('email') && !form.get(path).hasError('required')" class="alert alert-danger">{{ 'Subscription 1.Not Email' | translate:lang }}</div>
  <div *ngIf="form.get(path).hasError('minlength')" class="alert alert-danger">{{ 'Subscription 1.Password Min' | translate:lang }}</div>
  <div *ngIf="form.get(path).hasError('weakPassword')" class="alert alert-danger">{{ 'Subscription 1.Password Weak' | translate:lang }}</div>
  <div *ngIf="form.get(path).hasError('notEquivalent')" class="alert alert-danger">{{ 'Subscription 1.Password Equivalence' | translate:lang }}</div>
  <div *ngIf="form.get(path).hasError('outerError')" class="alert alert-danger">{{ form.get(path).errors['outerError'] }}</div>

  <div *ngIf="form.get(path).hasError('api')" class="alert alert-danger">
    <div *ngFor="let error of form.get(path).errors.api">
      {{error}}
    </div>
  </div>
</div>
