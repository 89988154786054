
import {takeUntil, distinctUntilChanged} from 'rxjs/operators';
import * as _ from 'underscore';
import { Component, AfterViewInit, Renderer2, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Language, TranslationService } from 'angular-l10n';

import { SubscriptionFormService } from '../subscription-form.service';
import { SubscriptionFormValuesService } from '../subscription-form-values.service';
import { CitiesService, CitiesComponent, SearchService, SharedDataService, Debounce } from '../../shared/';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'blitz-subscription-step-1',
  templateUrl: './subscription-step-1.component.html',
  styleUrls: ['./subscription-step-1.component.scss', '../subscription-steps.shared.scss'],
  providers: [CitiesService, SubscriptionFormService]
})
export class SubscriptionStep1Component extends CitiesComponent implements OnInit, AfterViewInit {
  @Language() lang: string;
  userForm: UntypedFormGroup;
  selectedCivility: string;
  civilityList: Array<{ name: string, value: string }> = [];
  citiesUrl: string;

  constructor(
    public citiesService: CitiesService,
    private translation: TranslationService,
    private sharedDataService: SharedDataService,
    private searchService: SearchService,
    private router: Router,
    protected renderer: Renderer2,
    public subscriptionFormService: SubscriptionFormService,
    private subscriptionFormValuesService: SubscriptionFormValuesService
  ) {
    super(citiesService, renderer);
    this.lazyLoadFilter();
    this.userForm = this.subscriptionFormService.userForm;
    this.fillFormServiceWithValues();
    this.prepareCities();
  }

  ngOnInit() {
    this.initCivility();
  }

  ngAfterViewInit() {
    const lang = !!this.lang ? this.lang : 'fr';
    this.citiesUrl = `${environment.API_URL}/${lang}/api/v1/cities/cities/`;
    this.userForm.valueChanges.subscribe(() => {
      if (!this.subscriptionFormValuesService.isFormTouched) {
        this.subscriptionFormValuesService.isFormTouched = true;
      }
    });
    this.userForm.get('city')
      .valueChanges.pipe(
      distinctUntilChanged())
      .subscribe(value => {
        if (value && value.id) {
            this.onCityChanged(value);
          return;
        }

        if (!value || (value && value.toString().trim().length < 1)) {
          this.autoSelectCities = null;
          return;
        }
        this.searchService.getLimitSubCities(this.citiesUrl, value, 40, this.selectedProvince && this.selectedProvince.id)
          .subscribe((resp: any) => {
            this.autoSelectCities = resp;
          });
      });
    this.disableSelectInputs();
  }

  private initCivility() {
    if (this.lang === 'fr') {
      this.civilityList = [{ name: 'M.', value: 'mr' }, { name: 'Mlle', value: 'ms' }, { name: 'Mme', value: 'mrs' }];
    } else {
      this.civilityList = [{ name: 'Mr', value: 'mr' }, { name: 'Ms', value: 'ms' }, { name: 'Mrs', value: 'mrs' }];
    }
    if (this.subscriptionFormValuesService.value['civility']) {
      this.selectedCivility = this.subscriptionFormValuesService.value['civility'];
    } else {
      this.selectedCivility = this.civilityList[0].value;
    }
  }

  private fillFormServiceWithValues() {
    if (this.subscriptionFormValuesService.value && !_.isEmpty(this.subscriptionFormValuesService.value)) {
      this.selectedCity = Object.assign({}, this.subscriptionFormValuesService.value['city']);
      this.onCityChanged(this.selectedCity);
      this.subscriptionFormService.afterSubmitValues = this.subscriptionFormValuesService.value;
    }
    if (this.subscriptionFormValuesService.errors && !_.isEmpty(this.subscriptionFormValuesService.errors)) {
      this.subscriptionFormService.outerErrors = _.mapObject(this.subscriptionFormValuesService.errors, (val: Array<string>, key) => {
        return val.join('&#13;&#10;');
      });
    }
  }

  private prepareCities() {
    let value = this.subscriptionFormValuesService.value;
    this.selectedCountry = value['country'] ? value['country'] : {};
    this.selectedProvince = value['region'] ? value['region'] : {};
    if (value && !_.isEmpty(value)) {
      this.citiesService.getCountries().pipe(
          takeUntil(this.ngUnsubscribe))
          .subscribe((resp) => {
            this.autoSelectCountries = resp;
            this.countries = resp;
            this.getProvinces().subscribe(
              () => {
                this.userForm.setValue(value);
                this.enableSelector(this.provinceSelector, this.userForm.controls.region);
                this.getCities().subscribe(
                  () => {
                    this.enableSelector(this.citySelector, this.userForm.controls.city);
                  }
                );
              }
            );
          });
    } else {
      this.getCountries();
    }
  }

  onSubmit() {
    this.userForm.value['city'] = this.selectedCity;
    this.subscriptionFormValuesService.isFormValid = true;
    this.subscriptionFormValuesService.value = this.userForm.value;
    if (!this.subscriptionFormValuesService.value['city']) {
      this.subscriptionFormValuesService.value['city'] = this.selectedCity;
    }
    _.extendOwn(this.subscriptionFormValuesService.valueToShow, this.userForm.value);
    this.subscriptionFormValuesService.valueToShow['civility'] = _.find(this.civilityList, l => l.value === this.selectedCivility).name;
    this.subscriptionFormValuesService.valueToShow['country'] = this.selectedCountry['title'];
    this.subscriptionFormValuesService.valueToShow['region'] = this.selectedProvince['title'];
    this.subscriptionFormValuesService.valueToShow['city'] = this.selectedCity['title'];
    this.router.navigate(['/subscribe/step-2']);
  }

  @Debounce(100)
  onCountryChanged() {
    let input = this.subscriptionFormService.userForm.controls;
    input.country.markAsTouched();
    if (!_.isUndefined(input.region.value) && !_.isNull(input.region.value)) {
      input.region.setValue(null);
    }
    if (!_.isUndefined(this.selectedCity) && !_.isNull(this.selectedCity)) {
      input.city.setValue(null);
      this.selectedCity = null;
      this.userForm.controls.city.disable();
    }
    this.getProvinces();
    this.enableSelector(this.provinceSelector, this.userForm.controls.region);
  }

  @Debounce(100)
  onProvinceChanged() {
    let input = this.subscriptionFormService.userForm.controls;
    input.region.markAsTouched();
    this.getCities();
    this.userForm.controls.city.enable();
    input.city.setValue(null);
    this.selectedCity = null;
  }

  markTouched(event) {
    this.subscriptionFormService.userForm.controls[event.currentTarget.id].markAsTouched();
  }

  getProvinceLabel(): string {
    if (this.userForm.value.country) {
      return this.translation.translate('Subscription 1.Province Placeholder');
    } else {
      return this.translation.translate('Subscription 1.Country First Placeholder');
    }
  }

  onCityChanged(city) {
    this.userForm.get('city').setValue(city && city.title ? city.title : '');
    this.selectedCity = city;
  }

  private lazyLoadFilter() {
    this.sharedDataService.getNeedUpdateFilter().subscribe((data: any) => {
      if (this.autoSelectCities && this.selectedCity && this.autoSelectCities.count && data && data.filterName === 'cities') {
        let nextLimit = data.limit + 40;
        if (this.autoSelectCities.count <= 40) {
          return;
        }
        let overLimit = ((nextLimit > this.autoSelectCities.count) && (data.limit > this.autoSelectCities.count));
        if (!overLimit) {
          this.searchService.getLimitSubCities(this.citiesUrl, this.selectedCity.title,
                                                nextLimit, this.selectedProvince && this.selectedProvince.id)
            .subscribe((resp: any) => {
              this.autoSelectCities = resp;
            });
        }
      }
    });
  }

  clickOutsideOfFilter(popover: any, ev: any, control: any, selected: any) {
    control.setValue(selected && selected.title ? selected.title : '');
    popover.hide(ev);
  }

  getCityLabel(): string {
    if (this.userForm.value.country && this.userForm.value.region) {
      return this.translation.translate('Subscription 1.City Placeholder');
    } else {
      return this.translation.translate('Subscription 1.Country and Province First Placeholder');
    }
  }
}
