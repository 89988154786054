import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie';

import { environment } from '../../environments/environment';
import { DefaultService } from './default.service';
import { SharedDataService } from './shared-data.service';
import { CurrentUser } from './model/current-user';
import { Profile } from './model/profile';
import { Debounce } from './tools';
import * as _ from 'underscore';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthService extends DefaultService {
  user = new BehaviorSubject<CurrentUser>(null);
  private headers: HttpHeaders;

  constructor(private http: HttpClient,
              public cookieService: CookieService,
              private router: Router,
              @Inject(DOCUMENT) private document: any,
              private sharedDataService: SharedDataService) {
    super(cookieService);
    this.headers = new HttpHeaders(
      {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    );
    this.user.next(localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : {});
  }

  getProfile(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(
      `${environment.API_URL}/${this.lang}/api/v1/users/profile/`,
      {headers: headers}
    ).pipe(
      tap((user: CurrentUser) => this.setFullUserInfo(user))
    );
  }

  login(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      }
    );
    return this.http.post<any>(
      `${environment.API_URL}/${this.lang}/api/v1/auth/login/`,
      {
        email: email,
        password: password
      },
      {headers: headers}
    );
  }

  resetPassword(email: string): Observable<any> {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('email', email);
    const body = urlSearchParams.toString();
    return this.http.post(
      `${environment.API_URL}/${this.lang}/api/v1/auth/password/reset/`,
      body,
      {headers: this.headers}
    );
  }

  changePassword(id: string | number, body: { [key: string]: string }): Observable<any> {
    const headers = this.getHeaders();
    return this.http.put(
      `${environment.API_URL}/${this.lang}/api/v1/auth/password/change/${id}/`,
      body,
      {headers}
    );
  }

  logout(withMessage = true, redirectToLogin = false) {
    if (withMessage) {
      this.sharedDataService.setMessageHandel('Header.Logout Message');
    }
    this.user.next(null);
    localStorage.removeItem('currentUser');

    redirectToLogin ? this.router.navigateByUrl('/login') : this.router.navigateByUrl('/');
  }

  private getHeaders() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let token = currentUser && currentUser.token;
    return (new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': `Token ${token}`
    }));
  }

  public getCurrentUser(): CurrentUser {
    return localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : {};
  }

  public isSuperuser() {
    const user = this.getCurrentUser();
    if (user.data) {
      return user.data.is_superuser;
    } else {
      return false;
    }
  }

  public getPermissions() {
    const user = this.getCurrentUser();
    if (user.data && user.data.permissions) {
      return user.data.permissions;
    } else {
      this.logout(false);
      this.document.location.href = '/';
    }
  }

  public getFullUserInfo(): Observable<CurrentUser> {
    return this.user.asObservable();
  }

  public setFullUserInfo(content: any): void {
    this.user.next(this.setCurrentUser(content));
  }

  public updateFullUserInfo(content: any): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    _.each(content, (propValue: any, propName: string) => {
      currentUser.data[propName] = propValue;
      if (propName === 'email') {
        currentUser[propName] = propValue;
      }
    });
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    this.user.next(currentUser);
  }

  @Debounce(200)
  public errorForbiddenHandler(err: any) {
    if (err && err.status === 403) {
      this.getProfile().subscribe(
        resp => this.updateFullUserInfo(resp)
      );
      this.logout(false);
      this.sharedDataService.setMessageHandel({
        message: 'MZ Shared.Forbidden Error Msg',
        status: 'error'
      });
      this.router.navigate(['/']);
    }
  }

  private setCurrentUser(content: Profile): CurrentUser {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    currentUser.data = content;
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    return currentUser;
  }
}
