import { Component, OnInit, ElementRef, HostListener, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'blitz-popover',
  template: '<ng-content></ng-content>',
})
export class PopoverComponent implements OnInit, AfterViewInit {
  isVisible: boolean;
  @Input() preventGlobalClickEvent = false;
  constructor(private el: ElementRef) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.el.nativeElement.addEventListener('hide', this.hide.bind(this));
  }

  public toggle(event?: any) {
    if (!event) {
      this.hide();
      return;
    }
    if (!this.isVisible) {
      this.hideAllPopovers();
      this.show(event);
    } else if (event.target === document.activeElement && document.activeElement.tagName === 'INPUT') {
      event.stopPropagation();
      return;
    } else {
      this.hide();
    }
  }

  private hide() {
    this.el.nativeElement.classList.remove('visible');
    this.isVisible = false;
  }

  private hideAllPopovers() {
    let nodeList = document.querySelectorAll('.blitz-popover.visible');
    for (let i = 0; i < nodeList.length; ++i) {
      nodeList[i].dispatchEvent(new CustomEvent('hide'));
    }
  }

  private show(event: any) {
    event.stopPropagation();
    this.isVisible = true;
    setTimeout(() => {
      this.el.nativeElement.classList.add('visible');
    });
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: Element) {
    this.hideOnClick(targetElement);
  }

  @HostListener('document:touchend', ['$event.target'])
  public onTouch(targetElement: Element) {
    this.hideOnClick(targetElement);
  }

  private hideOnClick(targetElement: Element) {
    // Hotfix -  prevent modal to close when click the dropdown menu probably due referent error
    if (targetElement.className.includes('mdl')) {
      return;
    }

    if (!this.isVisible || this.preventGlobalClickEvent) { return; }

    const clickedInside = this.el.nativeElement.contains(targetElement);

    if (!clickedInside) {
      if (this.el.nativeElement.parentElement.contains(targetElement) && targetElement.className.includes('fa-angle')) {
        return;
      }
      this.hide();
    }
  }
}
