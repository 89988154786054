import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Language } from 'angular-l10n';

@Component({
  selector: 'blitz-buttons-bar',
  templateUrl: './buttons-bar.component.html',
  styleUrls: ['./buttons-bar.component.scss']
})
export class ButtonsBarComponent implements OnInit {
  @Language() lang: string;
  @Input() previousBtnUrl: string;
  @Input() continueBtnUrl: string;
  @Input() continueDisabled: boolean;
  @Output() private onContinue = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit() { }

  onNext() {
    this.onContinue.emit();
    if (this.continueBtnUrl) {
      this.router.navigateByUrl(this.continueBtnUrl);
    }
  }
}
