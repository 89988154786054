import { Component, OnInit } from '@angular/core';
import { Language, TranslationService } from 'angular-l10n';

import { SubscriptionFormValuesService } from '../subscription-form-values.service';

@Component({
  selector: 'blitz-subscription-step-2',
  templateUrl: './subscription-step-2.component.html',
  styleUrls: ['./subscription-step-2.component.scss', '../subscription-steps.shared.scss']
})
export class SubscriptionStep2Component implements OnInit {
  @Language() lang: string;
  formValues: { [key: string]: any };

  constructor(public subscriptionFormValuesService: SubscriptionFormValuesService, public translation: TranslationService) {
    this.formValues = this.subscriptionFormValuesService.value ? this.subscriptionFormValuesService.valueToShow : {};
  }

  ngOnInit() { }

}
