<div class="credit-card-icon">
  <div class="credit-card-icon__front credit-card-icon__part" *ngIf="creditCard && !newCard">
    <div class="credit-card-icon__front-logo credit-card-icon__logo">
      {{ creditCard['brand'] }}
    </div>
    <p class="credit-card-icon_number">**** **** **** {{ creditCard['last4']}}</p>
    <div class="credit-card-icon__space-75">
    </div>
    <div class="credit-card-icon__space-25">
      <span class="credit-card-icon__label">Expires</span>
      <p class="credit-card-icon__info">{{ creditCard['exp_month'] }}/{{ creditCard['exp_year'] }}</p>
    </div>
  </div>

  <div class="credit-card-icon__front credit-card-icon__part" *ngIf="!creditCard && !newCard">
    <div class="credit-card-icon__front__no-card">
      <div>
        Aucune carte
      </div>
    </div>
  </div>

  <div class="credit-card-icon__front credit-card-icon__part" *ngIf="newCard">
    <div class="credit-card-icon__front__no-card">
      <div>
        Nouvelle carte
      </div>
    </div>
  </div>
</div>
