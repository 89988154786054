import { NgModule } from '@angular/core';

import { DateTimePipe } from './date.pipe';
import { SortObjectPipe } from './sort-objects.pipe';
import { FilterPipe } from './filter.pipe';
import { PhonePipe } from './phone.pipe';
import { UrlPipe } from './url.pipe';

@NgModule({
  declarations: [
    DateTimePipe,
    SortObjectPipe,
    FilterPipe,
    PhonePipe,
    UrlPipe
  ],
  exports: [
    DateTimePipe,
    SortObjectPipe,
    FilterPipe,
    PhonePipe,
    UrlPipe
  ]
})
export class CommonPipesModule {
}
