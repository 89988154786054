<div class="calendar-icon">
  <div class="calendar-icon__header">
    {{getYearLabel()}}
    <div class="calendar-icon__header__clip calendar-icon__header__clip--left"></div>
    <div class="calendar-icon__header__clip calendar-icon__header__clip--right"></div>
  </div>
  <div class="calendar-icon__content">
    <div class="calendar-icon__content__month">
      {{getMonthLabel()}}
    </div>
    <div class="calendar-icon__content__day">
      {{getDayNumber()}}
    </div>
    <div class="calendar-icon__content__day-long">
      {{getDayLabel()}}
    </div>
  </div>
</div>
