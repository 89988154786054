import { Injectable } from '@angular/core';
import { DefaultService } from '../../../shared';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseApi } from '../../../shared/model/ResponseAPI';
import { ExportResponse } from '../models/export-response';
import { Influencer } from '../models/influencer';

export interface SearchField {
  [field: string]: string;
}

@Injectable()
export class InfluencersService extends DefaultService {

  token: string;


  constructor(private httpClient: HttpClient,
              public cookieService: CookieService) {
    super(cookieService);
  }

  getHeaders() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;

    const options = {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Token ${this.token}`
    };
    return new HttpHeaders(options);
  }

  public searchInfluencers(search_fields: SearchField): Observable<ResponseApi<Influencer>|ExportResponse> {
    let url_api = `${environment.API_URL}/${this.lang}/api/v1/search/influencers/`;

    let params = new HttpParams();
    for (let [field, value] of Object.entries(search_fields)) {
      params = params.append(field, value);
    }

    return this.httpClient.get<ResponseApi<Influencer>|ExportResponse>(
      url_api,
      {
        headers: this.getHeaders(),
        params: params
      }
    );
  }

}
