
import {of as observableOf,  Subject ,  Observable } from 'rxjs';

import {takeUntil} from 'rxjs/operators';
import * as _ from 'underscore';
import { Component, OnInit, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Language } from 'angular-l10n';
import { MdlSelectComponent } from '@angular-mdl/select';

import { CitiesService, Country, Province, City } from '../cities.service';

@Component({
  templateUrl: './cities.component.html',
  providers: [CitiesService]
})
export class CitiesComponent implements OnInit, OnDestroy {
  @ViewChild('countryEl') protected countrySelector;
  @ViewChild('provinceEl') protected provinceSelector;
  @ViewChild('cityEl') protected citySelector;
  @Language() lang: string;
  selectedCountry: any | undefined | null;
  selectedProvince: any | undefined | null;
  selectedCity: any | undefined | null;
  protected countries: Country[] = [];
  protected provinces: Province[] = [];
  protected cities: City[] = [];
  autoSelectCountries: Country[] = [];
  autoSelectProvinces: Province[] = [];
  autoSelectCities: any = [];
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(protected citiesService: CitiesService, protected renderer: Renderer2) {
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected getCountries() {
    this.citiesService.getCountries().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((countries) => {
        this.countries = countries;
        this.autoSelectCountries = countries;
      });
  }

  protected getProvinces(): Observable<any> {
    return observableOf(
      this.citiesService.getProvinces(this.selectedCountry.id).subscribe(
        provinces => {
          this.provinces = provinces.results;
          this.autoSelectProvinces = provinces.results;
        }
      )
    );
  }

  protected getCities(): Observable<any> {
    return observableOf(
      this.citiesService.getCities(this.selectedProvince.id).subscribe(
        (cities: any) => {
          this.cities = cities.results;
          this.autoSelectCities = cities.results;
          return null;
        }
      )
    );
  }

  onAutocompleteCountriesChange(value: string): any {
    return this.autoSelectCountries = this.countries
      .filter((country: any) => country['title'].toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onAutocompleteProvincesChange(value: string): any {
    return this.autoSelectProvinces = this.provinces
      .filter((province: any) => province['title'].toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onAutocompleteCitiesChange(value: string): any {
    this.autoSelectCities = this.cities
      .filter((city: any) => city['title'].toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  protected disableSelectInputs() {
    if (!this.countrySelector || !this.provinceSelector || !this.citySelector) { return; }
    let possiblyDisabled = [this.countrySelector, this.provinceSelector, this.citySelector];
    _.each(possiblyDisabled, $el => {
      let textField = $el.selectInput.nativeElement.parentElement;
      if (textField.className.includes('is-disabled')) {
        this.disableSelector($el);
      } else {
        this.enableSelector($el);
      }
    });
  }

  protected enableSelector(selector: MdlSelectComponent, control?: AbstractControl): void {
    if (control) {
      control.enable();
    }
    selector.setDisabledState(false);
    this.renderer.removeAttribute(selector.selectInput.nativeElement, 'disabled');
  }

  protected disableSelector(selector: MdlSelectComponent, control?: AbstractControl): void {
    if (control) {
      control.disable();
    }
    selector.setDisabledState(true);
    this.renderer.setAttribute(selector.selectInput.nativeElement, 'disabled', 'disabled');
  }

  resetCitiesComponent() {
    this.autoSelectCountries = [];
    this.autoSelectProvinces = [];
    this.autoSelectCities = [];
    this.selectedCountry = null;
    this.selectedProvince = null;
    this.selectedCity = null;
  }
}
