<span (document:click)="onClick($event, $event.target)">
  <div id="hamburger-wrapper" class="hamburger-wrapper">
    <button (click)="menuToggle()" class="hamburger hamburger--collapse" type="button"
            [ngClass]="{'is-active': menuState, '': !menuState}">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
  </div>
  <div class="menu-container cuppa-menu" [ngClass]="{'show-menu': menuState, 'hide-menu': !menuState}">
    <ul *ngIf="menuList?.length">
      <li (click)="menuToggle()">
        <a *ngIf="!user?.email" (click)="toggleLogin(); false">
          {{ 'Header.SignIn' | translate:lang }}
        </a>
      </li>
      <li *ngFor="let item of menuList" (click)="menuToggle()">
        <a *ngIf="item['link']" [routerLink]="item['link']">{{ item['title'] }} <sup *ngIf="item['id'] === 'media_match'">MC</sup></a>
        <a *ngIf="!item['link']" target='_blank' [href]="item['external_link']">{{ item['title'] }} <sup *ngIf="item['id'] === 'media_match'">MC</sup></a>
      </li>
    </ul>
  </div>
  <blitz-login [showModal]="showModal" (close)="close()"></blitz-login>
</span>
