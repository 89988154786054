import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'blitzSortObject' })
export class SortObjectPipe implements PipeTransform {
  public transform(value: any[], keyname: string, lang = 'fr') {
    if (!value) {
      return;
    }
    if (keyname[0] === '-') {
      keyname = keyname.substring(1);
    }
    const result: any[] = [...value];
    result.sort((a: any, b: any) => {
      return a[keyname].localeCompare(b[keyname], lang, { sensitivity: 'base' });
    });
    return result;
  }
}
