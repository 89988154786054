import { Component, OnInit } from '@angular/core';
import { Language } from 'angular-l10n';
import { RegistrationService } from '../registration.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'blitz-subscription-step-5',
  templateUrl: './subscription-step-5.component.html',
  styleUrls: [ '../subscription-steps.shared.scss', './subscription-step-5.component.scss'],
})
export class SubscriptionStep5Component implements OnInit {
  @Language() lang: string;
  public success = null;

  constructor(private registrationService: RegistrationService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.registrationService
        .activateAccount(params['activationToken'])
        .then((resolved) => this.handleSubmitSuccess(resolved))
        .catch((rejected) => this.handleSubmitError(rejected));
    });
  }

  private handleSubmitSuccess(resolved) {
    this.success = true;
  }

  private handleSubmitError(rejected) {
    this.success = false;
  }
}
