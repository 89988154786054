import * as _ from 'underscore';
import {
  Component, EventEmitter, AfterViewInit, Output, ElementRef, HostListener, Renderer2, Input, OnDestroy
} from '@angular/core';
import { Language } from 'angular-l10n';
import { Subject } from 'rxjs';

import {
  Debounce,
  CurrentUser,
  MenuListDto,
  SharedDataService, AuthService
} from '../../shared';

@Component({
  selector: 'blitz-slide-menu',
  templateUrl: 'slide-menu.component.html',
  styleUrls: ['slide-menu.component.scss']
})

export class SlideMenuComponent implements AfterViewInit, OnDestroy {
  @Output() private toggleHeaderBack: EventEmitter<number> = new EventEmitter<number>();
  @Input() set onUserChange(data) {
    this.user = data;
    this.initMenuList();
  };
  @Language() lang: string;
  public menuState: boolean;
  public user: CurrentUser;
  public showModal: boolean;
  public menuList: Array<{ [key: string]: string }>;
  private menuListType: MenuListDto;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(public el: ElementRef,
              private sharedDataService: SharedDataService,
              private renderer: Renderer2,
              private authService: AuthService) {
    this.menuState = false;
    this.menuListType = this.sharedDataService.getMenuListTypes();
  }

  ngAfterViewInit() {
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public initMenuList() {
    if (this.user && this.user.email) {
      const user_permissions = this.authService.getPermissions();
      if (user_permissions.lastIndexOf('has_access_base') >= 0) {
        this.menuList = _.reject(this.menuListType.logged, item => {
          if (user_permissions.lastIndexOf('can_access_mm') < 0) {
            return item['id'] === 'media_match';
          }
        });
      } else {
        this.menuList = this.menuListType.notLogged;
      }
    }
  }

  public menuToggle() {
    this.menuState = !this.menuState;
    this.toggleHeaderBack.emit();
    this.toggleFixedPosition();
  }

  public toggleLogin() {
    this.showModal = !this.showModal;
  }

  public close() {
    this.showModal = false;
  }

  public onClick(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement || !this.menuState) {
      return;
    }

    const clickedInside = this.el.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.menuToggle();
    }
  }

  @HostListener('window:resize')
  @Debounce()
  onResize() {
    if (screen.width >= 768 && this.menuState) {
      this.menuToggle();
    }
  }

  private toggleFixedPosition() {
    if (this.menuState) {
      this.renderer.addClass(document.body, 'no-scroll');
    } else {
      this.renderer.removeClass(document.body, 'no-scroll');
    }
  }
}
