import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { SharedDataService } from '../shared-data.service';

@Directive({
  selector: '[blitzScroll]',
})
export class ScrollDirective implements OnInit {
  @Input('bsFilterName') public bsFilterName: string;
  private el: HTMLElement;
  private limit: number;
  private nextLimit: number;

  constructor(private elementRef: ElementRef,
              private sharedDataService: SharedDataService) {
    this.limit = 0;
    this.nextLimit = 40;
  }

  public ngOnInit() {
    this.el = this.elementRef.nativeElement.getElementsByClassName('mdl-list')[0];
    this.el.addEventListener('scroll', (data) => {
      if (!data.target['scrollHeight']) { return; }
      if (data.target['clientHeight'] + data.target['scrollTop'] === data.target['scrollHeight']) {
        this.limit += this.nextLimit;
        this.sharedDataService.setNeedUpdateFilter({filterName: this.bsFilterName, limit: this.limit});
      }
    });
  }
}
