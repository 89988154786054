import { PipeTransform, Pipe } from '@angular/core';
import * as  moment from 'moment';

@Pipe({ name: 'blitzDateTime' })
export class DateTimePipe implements PipeTransform {
  private readonly mediumFormat = 'DD MMM YYYY, HH:mm';
  private readonly fullFormat = 'dddd, DD MMMM YYYY, HH:mm:ss';
  private readonly shortFormat = 'DD MMM YYYY';

  public transform (value: string | Date, type: string, lang = 'fr') {
    if (value) {
      moment.locale(lang);
      let date = moment(value);
      if (date.isValid()) {
        switch (type) {
          case 'short':
            return date.format(this.shortFormat);
          case 'full':
            return date.format(this.fullFormat);
          default:
            return date.format(this.mediumFormat);
        }
      } else {
        return value;
      }
    }
    return value;
  }
}
