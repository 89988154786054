import * as _ from 'underscore';
import { Injectable } from '@angular/core';

@Injectable()
export class SubscriptionFormValuesService {
  value = {};
  valueToShow = {};
  errors = null;
  isFormValid = false;
  isFormTouched = false;
  isAgreementConfirmed = false;
  isMailAgreementConfirmed = false;
  areEnabledAgreementCheckboxes = false;

  constructor() { }

  public formalizedValues(): Object {
    return _.omit(this.value, (value) => {
      return _.isEmpty(value) && !_.isBoolean(value) && !_.isNumber(value);
    });
  }

  reset() {
    this.value = {};
    this.valueToShow = {};
    this.errors = {};
    this.isFormValid = false;
    this.isFormTouched = false;
    this.isAgreementConfirmed = false;
    this.isMailAgreementConfirmed = false;
    this.areEnabledAgreementCheckboxes = false;
  }
}
