import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Language, TranslationService } from 'angular-l10n';
import { HeaderService } from '../shared/header.service';

import { Debounce } from './../shared';

@Component({
  selector: 'blitz-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @Language() lang: string;
  title: string;
  banner: string;
  subtitleTop: string;
  slides: Array<{ title: string, text: string, imageUrl: string }>;
  bottomImages: Array<{ text: string, imageUrl: string }>;

  constructor(private translation: TranslationService,
              private headerService: HeaderService,
              private route: ActivatedRoute) {
    this.title = this.translation.translate('Homepage.Top Title');
    this.subtitleTop = this.translation.translate('Homepage.Top SubTitle');
    this.banner = screen.width >= 767 ? '../../assets/img/homepage-top.jpg' : '../../assets/img/homepage-top-mobile.jpg';
    this.slides = [
      {
        title: this.translation.translate('Homepage.Slide1 Title'),
        text: this.translation.translate('Homepage.Slide1 Text'),
        imageUrl: screen.width >= 767 ? '../../assets/img/homepage-slide-image-1.jpg' : '../../assets/img/homepage-slide-image-1-mobile.jpg'
      },
      {
        title: this.translation.translate('Homepage.Slide2 Title'),
        text: this.translation.translate('Homepage.Slide2 Text'),
        imageUrl: screen.width >= 767 ? '../../assets/img/homepage-slide-image-2.jpg' : '../../assets/img/homepage-slide-image-2-mobile.jpg'
      }
    ];
    this.bottomImages = [
      {
        text: this.translation.translate('Homepage.Bottom Image1 Text'),
        imageUrl: '../../assets/img/homepage-bottom-image-1.jpg'
      },
      {
        text: this.translation.translate('Homepage.Bottom Image2 Text'),
        imageUrl: '../../assets/img/homepage-bottom-image-2.jpg'
      },
      {
        text: this.translation.translate('Homepage.Bottom Image3 Text'),
        imageUrl: '../../assets/img/homepage-bottom-image-3.jpg'
      }
    ];
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    const { returnUrl } = this.route.snapshot.queryParams;
    if (returnUrl) {
      this.headerService.setShowLoginModal(true);
    }
  }

  @HostListener('window:resize')
  @Debounce()
  onResize() {
    if (window.innerWidth >= 768 && (this.slides[0].imageUrl !== '../../assets/img/homepage-slide-image-1.jpg'
        || this.slides[1].imageUrl !== '../../assets/img/homepage-slide-image-2.jpg')) {
      this.slides[0].imageUrl = '../../assets/img/homepage-slide-image-1.jpg';
      this.slides[1].imageUrl = '../../assets/img/homepage-slide-image-2.jpg';
      this.banner = '../../assets/img/homepage-top.jpg';
    } else if (window.innerWidth < 768 && (this.slides[0].imageUrl !== '../../assets/img/homepage-slide-image-1-mobile.jpg'
               || this.slides[1].imageUrl !== '../../assets/img/homepage-slide-image-2-mobile.jpg')) {
      this.slides[0].imageUrl = '../../assets/img/homepage-slide-image-1-mobile.jpg';
      this.slides[1].imageUrl = '../../assets/img/homepage-slide-image-2-mobile.jpg';
      this.banner = '../../assets/img/homepage-top-mobile.jpg';
    }
  }
}
