import { Component, OnInit } from '@angular/core';

import { Language, TranslationService } from 'angular-l10n';

@Component({
  selector: 'blitz-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  @Language() lang: string;
  title: string;
  banner: string;

  constructor(private translation: TranslationService) {
    this.title = this.translation.translate('Services.Top Title');
    this.banner = '../../assets/img/services-top.jpg';
  }

  ngOnInit() {
  }

}
