<blitz-top [title]="title" [banner]="banner"></blitz-top>
<section class="services">
  <div class="services__list">
    <div class="services__list__item">
      <div class="services__list__item__image">
          <img src="../../assets/img/services-image-1.jpg">
      </div>
      <div class="services__list__item__detail">
        <div class="services__list__item__detail__title">
          {{ 'Services.Text 1 Title' | translate:lang }}
        </div>
        <div class="services__list__item__detail__content">
          <p>{{ 'Services.Text 1 Line 1' | translate:lang }}</p>
          <p>{{ 'Services.Text 1 Line 2' | translate:lang }}</p>
          <ul>
            <li>{{ 'Services.Text 1 Line 3' | translate:lang }}</li>
            <li>{{ 'Services.Text 1 Line 4' | translate:lang }}</li>
            <li>{{ 'Services.Text 1 Line 5' | translate:lang }}</li>
          </ul>
          <p>{{ 'Services.Text 1 Line 6' | translate:lang }}</p>
          <p>{{ 'Services.Text 1 Line 7' | translate:lang }}</p>
          <p>{{ 'Services.Text 1 Line 8' | translate:lang }}</p>
          <p>{{ 'Services.Text 1 Line 9' | translate:lang }}</p>

          <button type="button" class="common-btn blue" (click)="popover1.toggle($event)">{{ 'Services.Demo Button' | translate:lang }}</button>
          <blitz-popover class="blitz-popover" #popover1>
            <button class="blitz-popover__close" (click)="popover1.toggle($event)">
              &#10005;
            </button>
            <div>
              <div>{{ 'Homepage.Demo Message' | translate:lang }}</div>
              <div>
                <a href="mailto:info@datablitz.com">{{ 'Homepage.Demo Email Link' | translate:lang }}info@datablitz.com</a>
              </div>
              <div>Tél.: 1 888 997-1801</div>
            </div>
          </blitz-popover>
          <button type="button" class="common-btn blue" [routerLink]="['/subscribe/step-1']">
            {{ 'Homepage.Subscribe Button' | translate:lang }}
          </button>
        </div>
      </div>
    </div>

    <div class="services__list__item">
      <div class="services__list__item__image">
        <img src="../../assets/img/services-image-2.jpg">
      </div>
      <div class="services__list__item__detail">
        <div class="services__list__item__detail__title">
          {{ 'Services.Text 2 Title' | translate:lang }}
        </div>
        <div class="services__list__item__detail__content">
          <p>{{ 'Services.Text 2 Line 1' | translate:lang }}</p>
          <p>{{ 'Services.Text 2 Line 2' | translate:lang }}</p>
          <p>{{ 'Services.Text 2 Line 3' | translate:lang }}</p>
          <p>{{ 'Services.Text 2 Line 4' | translate:lang }}</p>
          <p>{{ 'Services.Text 2 Line 5' | translate:lang }}</p>

          <button type="button" class="common-btn blue" (click)="popover2.toggle($event)">{{ 'Services.Demo Button' | translate:lang }}</button>
          <blitz-popover class="blitz-popover" #popover2>
            <button class="blitz-popover__close" (click)="popover2.toggle($event)">
              &#10005;
            </button>
            <div>
              <div>{{ 'Homepage.Demo Message' | translate:lang }}</div>
              <div>
                <a href="mailto:info@datablitz.com">{{ 'Homepage.Demo Email Link' | translate:lang }}info@datablitz.com</a>
              </div>
              <div>Tél.: 1 888 997-1801</div>
            </div>
          </blitz-popover>
          <button type="button" class="common-btn blue" [routerLink]="['/subscribe/step-1']">
            {{ 'Homepage.Subscribe Button' | translate:lang }}
          </button>
        </div>
      </div>
    </div>

    <div class="services__list__item">
      <div class="services__list__item__image">
        <img src="../../assets/img/services-image-plik.jpg">
      </div>
      <div class="services__list__item__detail">
        <div class="services__list__item__detail__title">
          Répertoire d'influenceurs
          <img class="services__list__item__detail__title__logo" alt="Plik" src="../../assets/img/PLIK-Logo.png"/>
        </div>
        <div class="services__list__item__detail__content">
          <p>
            Grâce à notre partenariat avec <a target="_blank" href="https://plik.co/">Plik.co</a>, le Club de Presse Blitz offre aussi un répertoire de milliers d'influenceurs numériques canadiens, catégorisés en fonction de leurs secteurs d'activité et réseaux sociaux de prédilection.
          </p>
          <p>
            Ce service fournit toute l'information permettant de contacter les influenceurs choisis.
          </p>
          <p>
            Le forfait Influenceurs est offert à 395 $ * pour un abonnement d’un an.
          </p>
          <p>
            * taxes non incluses
          </p>

          <button type="button" class="common-btn blue" (click)="popover3.toggle($event)">{{ 'Services.Demo Button' | translate:lang }}</button>
          <blitz-popover class="blitz-popover" #popover3>
            <button class="blitz-popover__close" (click)="popover3.toggle($event)">
              &#10005;
            </button>
            <div>
              <div>{{ 'Homepage.Demo Message' | translate:lang }}</div>
              <div>
                <a href="mailto:info@datablitz.com">{{ 'Homepage.Demo Email Link' | translate:lang }}info@datablitz.com</a>
              </div>
              <div>Tél.: 1 888 997-1801</div>
            </div>
          </blitz-popover>
          <button type="button" class="common-btn blue" [routerLink]="['/subscribe/step-1']">
            {{ 'Homepage.Subscribe Button' | translate:lang }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
