<blitz-top [title]="title" [banner]="banner" [subtitleTop]="subtitleTop" [buttons]="true"></blitz-top>
<section class="homepage-slides runway">
  <div class="runway-wrapper">
    <div class="slide" *ngFor="let slide of slides">
      <div class="text">
        <div class="text-wrapper">
          <div class="text-title">{{ slide.title | translate:lang }}</div>
          <div class="plain-text">{{ slide.text | translate:lang }}</div>
        </div>
      </div>
      <div class="image">
        <img src="{{ slide.imageUrl }}">
      </div>
    </div>
  </div>
</section>
<section class="imageless-info runway">
  <div class="runway-wrapper">
    <div class="text-wrapper">
      <div class="text-title">{{ 'Homepage.Imageless Title' | translate:lang }}</div>
      <div class="plain-text">{{ 'Homepage.Imageless Text' | translate:lang }}</div>
    </div>
    <div class="learn-more-wrapper">
      <button type="button" class="common-btn blue" [routerLink]="['/about']">{{ 'Homepage.Learn More Button' | translate:lang }}</button>
    </div>
  </div>
</section>
<section class="bottom-images runway">
  <div class="runway-wrapper">
    <div class="bottom-images-wrapper">
      <div class="bottom-image-text" *ngFor="let image of bottomImages">
        <div class="bottom-image">
          <img src="{{ image.imageUrl }}">
        </div>
        <div class="bottom-text">{{ image.text | translate:lang }}</div>
      </div>
    </div>
  </div>
</section>
