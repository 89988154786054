import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';


import { environment } from '../../environments/environment';
import { DefaultService } from './default.service';
import { Observable } from 'rxjs';

@Injectable()
export class QuickSearchService extends DefaultService {
  private headers: HttpHeaders;
  private token: string;

  constructor(private http: HttpClient,
              public cookieService: CookieService) {
    super(cookieService);
    this.setHeaders();
  }

  public getQuickSearchFilters(): Observable<any> {
    this.setHeaders();
    let url, options = {headers: this.headers};
    url = `${environment.API_URL}/${this.lang}/api/v1/quick-search/filters/`;
    return this.http.get(
        url,
        options
    );
  }

  public getQuickSearchResults(searchUrlPart = null): Observable<any> {
    this.setHeaders();
    const options = {headers: this.headers, body: {}};
    let url = `${environment.API_URL}/${this.lang}/api/v1/quick-search/stats/`;

    if (searchUrlPart) {
      url += '?' + searchUrlPart;
    }

    return this.http.get(
      url,
      options,
    );
  }

  public export(data: {[key: string]: any}): Observable<any> {
    this.setHeaders();

    let params = new HttpParams();
    params = params.set('department', data['department'].toString());
    params = params.set('media_type', data['media_type'].toString());
    params = params.set('export_to', data['export_to'].toString());

    const url = `${environment.API_URL}/${this.lang}/api/v1/quick-search/export/`;
    return this.http.get(
      url,
      {
        headers: this.headers,
        params: params,
      }
    );
  }

  public exportFiltered(data: {[key: string]: any}): Observable<any> {
    this.setHeaders();

    let params = new HttpParams();
    params = params.set(Object.keys(data)[0], Object.values(data)[0].toString());
    params = params.set('media_type', data['media_type'].toString());
    params = params.set('export_to', data['export_to'].toString());

    const url = `${environment.API_URL}/${this.lang}/api/v1/quick-search/export/`;
    return this.http.get(
      url,
      {
        headers: this.headers,
        params: params,
      }
    );
  }

  // todo: refactor this part
  /*
    private errorsHandler(err: any, name: string) {
      if (err && err.status === 500) {
        this.sharedDataService.setMessageHandel({
          message: `MZ Manage Favorites.${name}`,
          status: 'error'
        });
      }
    }
   */

  private setHeaders() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
    this.headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Token ${this.token}`
    });
  }
}
