<blitz-step-bar [stepDirector]="['blue', 'blue', 'blue', '', '']"></blitz-step-bar>
<section class="user-agreements">
  <div class="field-info-group text-area">
    <div class="field-title">{{ 'Subscription 3.Title' | translate:lang }} <span class=red-asterisk>*</span></div>
    <div class="field-info">
      <div *ngIf="lang === 'fr'">
        <div class="text-area-paragraph bold">{{ 'Subscription 3.FR Agreement Text Paragraph 1 Part 1' | translate:lang }}</div>
        <div>
          <span>{{ 'Subscription 3.FR Agreement Text Paragraph 1 Part 2' | translate:lang }}</span>
          <span class="bold">{{ 'Subscription 3.FR Agreement Text Paragraph 1 Part 3' | translate:lang }}</span>
          <span>{{ 'Subscription 3.FR Agreement Text Paragraph 1 Part 4' | translate:lang }}</span>
          <span class="bold">{{ 'Subscription 3.FR Agreement Text Paragraph 1 Part 5' | translate:lang }}</span>
          <span>{{ 'Subscription 3.FR Agreement Text Paragraph 1 Part 6' | translate:lang }}</span>
        </div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 1 Part 7' | translate:lang }}</div>
        <div class="text-area-paragraph bold">{{ 'Subscription 3.FR Agreement Text Paragraph 2 Part 1' | translate:lang }}</div>
        <div>
          <span>{{ 'Subscription 3.FR Agreement Text Paragraph 2 Part 2' | translate:lang }}</span>
          <ol type="a">
            <li>{{ 'Subscription 3.FR Agreement Text Paragraph 2 Part 3' | translate:lang }}</li>
            <li>{{ 'Subscription 3.FR Agreement Text Paragraph 2 Part 4' | translate:lang }}</li>
          </ol>
        </div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 2 Part 5' | translate:lang }}</div>
        <div class="text-area-paragraph bold">{{ 'Subscription 3.FR Agreement Text Paragraph 3 Part 1' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 3 Part 2' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 3 Part 3' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 3 Part 4' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 3 Part 5' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 3 Part 6' | translate:lang }}</div>
        <div>
          <span>{{ 'Subscription 3.FR Agreement Text Paragraph 3 Part 7' | translate:lang }}</span>
          <span class="italic">{{ 'Subscription 3.FR Agreement Text Paragraph 3 Part 8' | translate:lang }}</span>
          <span>{{ 'Subscription 3.FR Agreement Text Paragraph 3 Part 9' | translate:lang }}</span>
        </div>
        <div class="text-area-paragraph bold">{{ 'Subscription 3.FR Agreement Text Paragraph 4 Part 1' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 4 Part 2' | translate:lang }}</div>
        <div class="text-area-paragraph bold">{{ 'Subscription 3.FR Agreement Text Paragraph 5 Part 1' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 5 Part 2' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 5 Part 3' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 5 Part 4' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 5 Part 5' | translate:lang }}</div>
        <div>
          <span>{{ 'Subscription 3.FR Agreement Text Paragraph 5 Part 6' | translate:lang }}</span>
          <span class="italic">{{ 'Subscription 3.FR Agreement Text Paragraph 5 Part 7' | translate:lang }}</span>
          <span>{{ 'Subscription 3.FR Agreement Text Paragraph 5 Part 8' | translate:lang }}</span>
        </div>
        <div class="text-area-paragraph bold">{{ 'Subscription 3.FR Agreement Text Paragraph 6 Part 1' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 6 Part 2' | translate:lang }}</div>
        <div class="text-area-paragraph bold">{{ 'Subscription 3.FR Agreement Text Paragraph 7 Part 1' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 7 Part 2' | translate:lang }}</div>
        <div>
          <ul>
            <li>{{ 'Subscription 3.FR Agreement Text Paragraph 7 Part 3' | translate:lang }}</li>
            <li>{{ 'Subscription 3.FR Agreement Text Paragraph 7 Part 4' | translate:lang }}</li>
            <li>{{ 'Subscription 3.FR Agreement Text Paragraph 7 Part 5' | translate:lang }}</li>
            <li>{{ 'Subscription 3.FR Agreement Text Paragraph 7 Part 6' | translate:lang }}</li>
          </ul>
        </div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 7 Part 7' | translate:lang }}</div>
        <div class="text-area-paragraph bold">{{ 'Subscription 3.FR Agreement Text Paragraph 8 Part 1' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 8 Part 2' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 8 Part 3' | translate:lang }}</div>
        <div class="text-area-paragraph bold">{{ 'Subscription 3.FR Agreement Text Paragraph 9 Part 1' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 9 Part 2' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 9 Part 3' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 9 Part 4' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 9 Part 5' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 9 Part 6' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 9 Part 7' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 9 Part 8' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 9 Part 9' | translate:lang }}</div>
        <div>{{ 'Subscription 3.FR Agreement Text Paragraph 9 Part 10' | translate:lang }}</div>
        <div>
          <span>{{ 'Subscription 3.FR Agreement Text Paragraph 9 Part 11' | translate:lang }}</span>
          <a href="mailto:{{ email }}">{{ email }}</a>
        </div>
      </div>
      <div *ngIf="lang === 'en'">
        <div class="text-area-paragraph">{{ 'Subscription 3.Agreement Text Line 1' | translate:lang }}</div>
        <div class="text-area-paragraph">{{ 'Subscription 3.Agreement Text Line 2' | translate:lang }}</div>
      </div>
    </div>
  </div>
  <div class="checkbox-row">
    <div>
      <mdl-checkbox #agreementConfirmedEl [(ngModel)]="agreementConfirmed" (change)="onToggleAgreeCheckbox($event)" [value]="subscriptionFormValuesService.isAgreementConfirmed">{{ 'Subscription 3.Agree Checkbox' | translate:lang }}</mdl-checkbox>
    </div>
    <div>
      <mdl-checkbox #mailAgreementConfirmedEl [(ngModel)]="mailAgreementConfirmed" (change)="onToggleMailAgreeCheckbox($event)" [value]="subscriptionFormValuesService.isMailAgreementConfirmed">{{ 'Subscription 3.Mail Agree Checkbox' | translate:lang }}</mdl-checkbox>
    </div>
  </div>
</section>
<blitz-buttons-bar [previousBtnUrl]="'/subscribe/step-2'" [continueDisabled]="!subscriptionFormValuesService.isAgreementConfirmed && disableBtn" (onContinue)="onSubmitForm()"></blitz-buttons-bar>
