<div class="subscription-detail" *ngIf="subscription">
  <div class="subscription-detail__informations">
    <form class="subscription-detail__informations__form">
      <div class="subscription-detail__informations__form__field">
        <i class="subscription-detail__informations__form__field__icon fas fa-building" aria-hidden="true"></i>
        <span *ngIf="subscription.manager.company">{{ subscription.manager.company }}</span>
        <span *ngIf="!subscription.manager.company">-</span>
      </div>
      <div class="subscription-detail__informations__form__field">
        <i class="subscription-detail__informations__form__field__icon fas fa-user" aria-hidden="true"></i>
        <span>{{ subscription.manager.civility }} {{ subscription.manager.first_name }} {{ subscription.manager.last_name }}</span>
      </div>
      <div class="subscription-detail__informations__form__field">
        <i class="subscription-detail__informations__form__field__icon fas fa-envelope" aria-hidden="true"></i>
        <span>{{ subscription.manager.email }}</span>
      </div>
      <div class="subscription-detail__informations__form__field" *ngIf="!subscription.auto_renew">
        <i class="subscription-detail__informations__form__field__icon fas fa-refresh" aria-hidden="true"></i>
        <span>Renouvellement automatique</span>
      </div>
      <div class="subscription-detail__informations__form__field">
        <label>Date de début:</label>
        <span>{{ formatDay(subscription.start) }}</span>
      </div>
      <div class="subscription-detail__informations__form__field">
        <label>Date de fin:</label>
        <span>{{ formatDay(subscription.end) }}</span>
      </div>
    </form>

    <div class="subscription-detail__informations__dates" *ngIf="subscription.start">
      <blitz-calendar-icon class="subscription-detail__informations__dates__item" [date]="subscription.start">
      </blitz-calendar-icon>

      <i class="subscription-detail__informations__dates__item subscription-detail__informations__dates__item--arrow fas fa-angle-double-right"></i>

      <blitz-calendar-icon class="subscription-detail__informations__dates__item" [date]="subscription.end">
      </blitz-calendar-icon>
    </div>
  </div>

  <blitz-my-table class="subscription-detail__product" *ngIf="isSubscriptionApprove()" [data]="addons" [settings]="settings"></blitz-my-table>
  <blitz-my-table class="subscription-detail__product" *ngIf="!isSubscriptionApprove()" [data]="products" [settings]="settings_request"></blitz-my-table>

  <div class="subscription-detail__members" *ngIf="isSubscriptionApprove() && members">
    <div class="subscription-detail__members__title">
      UTILISATEURS ASSOCIÉS ({{members.length}}/{{subscription.number_of_seats}})
    </div>
    <blitz-my-table class="subscription-detail__members__table" [data]="members" [settings]="settings_members"></blitz-my-table>
  </div>

  <div class="subscription-detail__actions">
    <button class="button" [ngClass]="{'button--disabled': waitAPI}" (click)="approveSubscription()"
            [disabled]="waitAPI"
            *ngIf="!subscription.is_approved">
      <i class="fas fa-check"></i>
      Approuver l'abonnement
    </button>

    <button class="button button--danger"
            [ngClass]="{'button--disabled': waitAPI}"
            [disabled]="waitAPI"
            (click)="deleteSubscription()">
      <i class="fas fa-times"></i>
      {{ getDeleteSubscriptionLabel() }}
    </button>
  </div>
  <div class="subscription-detail__footer">
    <div class="subscription-detail__footer__manager-infos" *ngIf="subscriptionManager">
      <div class="subscription-detail__footer__manager-infos__title">
          Note sur le manager
      </div>
      <div class="subscription-detail__footer__manager-infos__content">
        <textarea
          id="managerNote"
          name="managerNote"
          [(ngModel)]="subscriptionManager.comment"
          class="text-input subscription-detail__footer__manager-infos__content__textarea">
        </textarea>
        <button class="button subscription-detail__footer__manager-infos__content__button"
                [ngClass]="{'button--disabled': waitAPI}"
                (click)="updateManager()">
          <i class="fas fa-check"></i>
          Mettre à jour le manager
        </button>
      </div>
    </div>
    <div class="subscription-detail__footer__payment-infos">
      <div class="subscription-detail__informations__payment">
        <div class="subscription-detail__informations__payment__title">
          Paiement
          <i *ngIf="!subscription.payment.completed"
             class="subscription-detail__informations__payment__title__edit fas fa-pencil-alt"
             (click)="editPayment()"
             aria-hidden="true"></i>
        </div>
        <div class="subscription-detail__informations__payment__content">
          <div class="subscription-detail__informations__payment__content__details">
            <div class="subscription-detail__informations__payment__content__details__status">
              <div *ngIf="subscription.payment.completed" class="subscription-detail__informations__payment__content__details__status__completed">
                <div class="subscription-detail__informations__payment__content__details__status__completed__element">
                  <div class="subscription-detail__informations__payment__content__details__status__completed__element__title">
                    Paiement effectué
                  </div>
                  <div class="subscription-detail__informations__payment__content__details__status__completed__element__value">
                    {{ subscription.payment.price | currency:'CAD' }}
                  </div>
                </div>
              </div>
              <div *ngIf="!subscription.payment.completed" class="subscription-detail__informations__payment__content__details__status__waiting">
                <div class="subscription-detail__informations__payment__content__details__status__waiting__element">
                  <div class="subscription-detail__informations__payment__content__details__status__waiting__element__title">
                    En attente de paiement
                  </div>
                  <div class="subscription-detail__informations__payment__content__details__status__waiting__element__value">
                    <i *ngIf="!subscription.payment.card_is_charged" class="far fa-clock"></i>
                    <span *ngIf="subscription.payment.card_is_charged">En attente de validation avec Stripe</span>
                  </div>
                </div>
                <div class="subscription-detail__informations__payment__content__details__status__waiting__element">
                  <div class="subscription-detail__informations__payment__content__details__status__waiting__element__title">
                    Nombre de jours de grâce :
                  </div>
                  <div class="subscription-detail__informations__payment__content__details__status__waiting__element__value">
                    {{ subscription.payment.days_before_due }}
                  </div>
                </div>
                <div class="subscription-detail__informations__payment__content__details__status__waiting__element">
                  <div class="subscription-detail__informations__payment__content__details__status__waiting__element__title">
                    Fin du délai de grâce :
                  </div>
                  <div class="subscription-detail__informations__payment__content__details__status__waiting__element__value">
                    {{ formatDay(subscription.start, subscription.payment.days_before_due) }}
                  </div>
                </div>
                <div class="subscription-detail__informations__payment__content__details__status__waiting__element">
                  <div class="subscription-detail__informations__payment__content__details__status__waiting__element__title">
                    Cout :
                  </div>
                  <div class="subscription-detail__informations__payment__content__details__status__waiting__element__value">
                    {{ subscription.payment.price | currency:'CAD' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <blitz-credit-card-icon class="subscription-detail__informations__payment__content__card" *ngIf="subscription.payment_type == 'cc'" [creditCard]="defaultCard"></blitz-credit-card-icon>
          <blitz-bank-check-icon *ngIf="subscription.payment_type == 'bill'"></blitz-bank-check-icon>
      </div>

      <button (click)="approvePayment()"
              *ngIf="hasButtonApprovePayment()"
              [disabled]="waitAPI"
              [ngClass]="{'button--disabled': waitAPI}"
              class="button payment_action">
        Valider le paiement
      </button>
      <button class="button payment_action "
              [disabled]="waitAPI"
              *ngIf="!subscription.payment.completed && subscription.payment_type == 'cc'"
              (click)="openCardModal()">
        <i class="fas fa-edit"></i>
        {{ labelForUpdateCardButton }}
      </button>
      <button class="button payment_action"
              [disabled]="waitAPI"
              *ngIf="hasButtonChargeCard()"
              (click)="chargeCardModal()">
        <i class="fas fa-edit"></i>
        Charger la carte de paiment
      </button>
    </div>
  </div>
</div>

<blitz-modal name="approve_payment"
             title="Approuver ?"
             buttonLabel="Approuver le paiment"
             (button)="approvePayment(true)"
             maxWidth="600px"
             [autoClose]="true">
  Êtes-vous sûr de vouloir approuver ce paiement ?
</blitz-modal>

<blitz-modal name="error_modale"
             title="Erreur"
             buttonLabel="Ok"
             maxWidth="600px"
             modalStyle="danger"
             [autoClose]="true">
  <div *ngFor="let error of errors">
    {{error}}
  </div>
</blitz-modal>

<blitz-modal name="approve_subscription"
             title="Approuver ?"
             buttonLabel="Approuver l'abonnement"
             (button)="approveSubscription(true)"
             maxWidth="600px"
             [autoClose]="true">
  Êtes-vous sûr de vouloir approuver cet abonnement ?
</blitz-modal>

<blitz-modal name="delete_subscription"
             modalStyle="danger"
             title="Refuser l'abonnement ?"
             buttonLabel="Supprimer l'abonnement"
             (button)="deleteSubscription(true)"
             maxWidth="600px"
             [autoClose]="true">
  <p>
    Êtes-vous sûr de vouloir supprimer ou refuser cet abonnement ?
  </p>
  <p>
    Un abonnement refusé ou supprimé ne peut pas être retrouvé ultérieurement. Cette action est
    définitive.
  </p>
</blitz-modal>

<blitz-modal name="edit_payment"
             modalStyle="form"
             title="Modifier le paiement"
             buttonLabel="Modifier"
             (button)="editPayment(true)"
             maxWidth="600px"
             [autoClose]="true"
             *ngIf="subscription">
  <label for="daysBeforeDue" class="subscription-detail__edit-payment-modal__label">
    Nombre de jours de grâce :
  </label>
  <input id="daysBeforeDue" name="daysBeforeDue" class="input" [(ngModel)]="temporaryDaysBeforeDue" type="number"/>
  <label class="subscription-detail__edit-payment-modal__label">
    Fin du délai de grâce :
  </label>
  <label>
    {{ formatDay(subscription.start, temporaryDaysBeforeDue) }}
  </label>
  <p></p>
  <label for="price" class="subscription-detail__edit-payment-modal__label">
    Prix avec taxes : CAD
  </label>
  <input
    id="price"
    name="price"
    class="input"
    [(ngModel)]="temporaryPrice"
    type="float"/>
  <p></p>
  <div class="payment-type">
    <div class="text-wrapper">
      <div class="text-title">{{ 'Subscription 5.Payment Type' | translate:lang }}</div>
    </div>
    <div class="radiobuttons-wrapper">
      <div class="radiobuttons">
        <div class="radio radio-datablitz">
          <mdl-radio name="payment_type" [(ngModel)]="temporaryPaymentType" value="cc">Carte de crédit</mdl-radio>
        </div>
        <div class="radio radio-mediamatch">
          <mdl-radio name="payment_type" [(ngModel)]="temporaryPaymentType" value="bill">Chèque</mdl-radio>
        </div>
      </div>
    </div>
  </div>
  <p></p>
  <blitz-alert
    *ngIf="subscription.payment.price!=temporaryPrice"
    [messages]="alertPrice">
  </blitz-alert>
  <p></p>
  <blitz-alert
    *ngIf="hasAlertCC()"
    [messages]="alertCC">
  </blitz-alert>
</blitz-modal>

<blitz-modal name="change_manager_card"
             modalStyle="form"
             title="Modifier la carte du manager"
             buttonLabel="Modifier la carte du manager"
             (button)="updateManagerCard()"
             maxWidth="700px"
             [autoClose]="false">
  <div class="credit-card-form">
    <div class="credit-card-form-wrapper">
      <form [formGroup]="ccForm" novalidate>
        <div class="row">
          <div class="input-wrapper card-number-input-wrapper">
            <label for="cc_number">{{ 'Subscription 5.Card Number' | translate:lang }}</label>
            <input class="text-input" formControlName="cc_number" name="cc_number" id="cc_number"
                   placeholder="{{ 'Subscription 5.Card Number' | translate:lang }}">
            <div *ngIf="ccForm.get('cc_number').touched && ccForm.get('cc_number').hasError('required')"
                 class="alert alert-danger">{{ 'Subscription 5.Card Number' | translate:lang }} {{ 'Subscription 5.Required' | translate:lang }}</div>
            <div
              *ngIf="ccForm.get('cc_number').touched && ccForm.get('cc_number').hasError('pattern') && !ccForm.get('cc_number').hasError('required')"
              class="alert alert-danger">{{ 'Subscription 5.Doesn\'t Look Like' | translate:lang }} {{ 'Subscription 5.Card Number' | translate:lang }}</div>
          </div>
          <div class="input-wrapper card-cvc-input-wrapper">
            <label for="cc_cvc">{{ 'Subscription 5.CVC Number' | translate:lang }}</label>
            <input class="text-input" formControlName="cc_cvc" name="cc_cvc" id="cc_cvc"
                   placeholder="{{ 'Subscription 5.CVC Number' | translate:lang }}">
            <div *ngIf="ccForm.get('cc_cvc').touched && ccForm.get('cc_cvc').hasError('required')"
                 class="alert alert-danger">{{ 'Subscription 5.CVC Number' | translate:lang }} {{ 'Subscription 5.Required' | translate:lang }}</div>
          </div>
        </div>
        <div class="row">
          <div class="input-wrapper card-expiration-month-input-wrapper">
            <label for="cc_exp_month">{{ 'Subscription 5.Expiration Month' | translate:lang }}</label>
            <mdl-select formControlName="cc_exp_month" id="cc_exp_month" class="mdl-select form-control"
                        [label]="'Subscription 5.Expiration Month' | translate:lang">
              <mdl-option *ngFor="let month of ccMonths" [value]="month">{{ month }}</mdl-option>
            </mdl-select>
            <div *ngIf="ccForm.get('cc_exp_month').touched && ccForm.get('cc_exp_month').hasError('required')"
                 class="alert alert-danger">{{ 'Subscription 5.Expiration Month' | translate:lang }} {{ 'Subscription 5.Required' | translate:lang }}</div>
          </div>
          <div class="input-wrapper card-expiration-year-input-wrapper">
            <label for="cc_exp_year">{{ 'Subscription 5.Expiration Year' | translate:lang }}</label>
            <mdl-select formControlName="cc_exp_year" id="cc_exp_year" class="mdl-select form-control"
                        [label]="'Subscription 5.Expiration Year' | translate:lang">
              <mdl-option *ngFor="let year of ccYears" [value]="year">{{ year }}</mdl-option>
            </mdl-select>
            <div *ngIf="ccForm.get('cc_exp_year').touched && ccForm.get('cc_exp_year').hasError('required')"
                 class="alert alert-danger">{{ 'Subscription 5.Expiration Year' | translate:lang }} {{ 'Subscription 5.Required' | translate:lang }}</div>
          </div>
        </div>
        <div #stripeErrorEl class="stripe-errors alert alert-danger">{{ stripeError }}</div>
      </form>
    </div>
  </div>
</blitz-modal>

<blitz-modal name="charge_card"
             modalStyle="form"
             title="Effectuer le paiement par carte de credit"
             buttonLabel="Charger la carte"
             (button)="chargeCard()"
             [activated]="chargeCardValidationModal"
             maxWidth="600px"
             [autoClose]="true">
  <div class="charge-card-modal" *ngIf="defaultCard">
    <div class="charge-card-modal__content">
        Etes-vous sur de vouloir charger la carte de credit de ce manager ?

        <blitz-credit-card-icon class="charge-card-modal__content__card" *ngIf="defaultCard" [creditCard]="defaultCard"></blitz-credit-card-icon>
    </div>

    <mdl-checkbox class="charge-card-modal__validation" [(ngModel)]="chargeCardValidationModal">
      Je me suis renseigné sur les conséquences de mon acte et en prend l'entière responsabilité
    </mdl-checkbox>
  </div>
</blitz-modal>
