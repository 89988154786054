import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'blitz-bank-check-icon',
  templateUrl: './bank-check-icon.component.html',
  styleUrls: ['./bank-check-icon.component.scss']
})
export class BankCheckIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
