import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdlSelectModule } from '@angular-mdl/select';
import { MdlModule } from '@angular-mdl/core';
import { TranslationModule } from 'angular-l10n';
import { PopoverComponent } from './popover.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PopupNotificationComponent } from './popup-notification/popup-notification.component';
import { ValidationMessagesComponent } from './validation-messages/validation-messages.component';
import { CenteredPopoverComponent } from './centered-popover/centered-popover.component';
import { CreditCardIconComponent } from '../credit-card-icon/credit-card-icon.component';
import { MyModalComponent } from '../my-modal/my-modal.component';

@NgModule({
  imports: [
    CommonModule,
    MdlSelectModule,
    MdlModule,
    TranslationModule
  ],
  declarations: [
    PopoverComponent,
    SpinnerComponent,
    ValidationMessagesComponent,
    PopupNotificationComponent,
    CenteredPopoverComponent,
    CreditCardIconComponent,
    MyModalComponent,
  ],
  exports: [
    PopoverComponent,
    SpinnerComponent,
    ValidationMessagesComponent,
    PopupNotificationComponent,
    CenteredPopoverComponent,
    CreditCardIconComponent,
    MyModalComponent,
  ]
})
export class CommonComponentsModule {}
