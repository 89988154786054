import {of as observableOf,  Observable ,  BehaviorSubject } from 'rxjs';

import {map} from 'rxjs/operators';
import * as _ from 'underscore';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { DefaultService } from './default.service';
import { SharedDataService } from './shared-data.service';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class SearchService extends DefaultService {
  token: string;
  searchType: string;
  url: string;
  searchTypeHandle = new BehaviorSubject<any>(null);
  chips = [];
  chipsHandle = new BehaviorSubject<any>([]);
  removedChipsHandle = new BehaviorSubject<any>(null);
  query: { [key: string]: any };
  searchParams = {};
  private headers: HttpHeaders;
  private queryHandle = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient,
              private sharedDataService: SharedDataService,
              public cookieService: CookieService) {
    super(cookieService);
    this.setHeaders();
  }

  public getQueryParams(): Observable<any> {
    return this.queryHandle.asObservable();
  }

  public updateHeaders(): any {
    this.setHeaders();
  }

  public setQueryParams(content: any): void {
    this.deleteSearchParams({ filterName: 'offset' });
    this.query = content;
    this.queryHandle.next(content);
    this.sharedDataService.setPagCurrentPage({ page: 1 });
  }

  public setSearchType(content: string, withFavorite?: boolean): void {
    if (!withFavorite) {
      this.cleanChips();
    }
    this.searchType = content;
    this.searchTypeHandle.next(content);
    this.sharedDataService.setPagCurrentPage({ page: 1 });
  }

  public getSearchType(): Observable<any> {
    return this.searchTypeHandle.asObservable();
  }

  public getChips(): Observable<any> {
    return this.chipsHandle.asObservable();
  }

  public addChip(content: { [key: string]: any }): void {
    this.chips.push(content);
    this.chipsHandle.next(this.chips);
  }

  public addChips(content: { [key: string]: any }): void {
    const storage = [];
    _.each(_.keys(content), key => {
      let inputType = null;
      if (key === 'types' || key === 'diffusions') {
        inputType = 'checkbox';
      }
      _.each(content[key], (f: any) => {
        storage.push({ ...f, filterName: key, inputType: inputType });
      });
    });
    this.chips = storage;
    this.chipsHandle.next(this.chips);
  }

  public getRemovedChip(): Observable<any> {
    return this.removedChipsHandle.asObservable();
  }

  public removeChip(content: string): void {
    this.removedChipsHandle.next(content);
    this.chips = _.reject(this.chips, (chip: any) => chip.title.toLowerCase() === content.toLowerCase());
    this.chipsHandle.next(this.chips);
  }

  public cleanChips(): void {
    this.chips = [];
    this.chipsHandle.next(this.chips);
  }

  public clearChips(content: { [key: string]: any }[]): void {
    _.each(content, (c: any) => {
      this.chips = _.reject(this.chips, (chip: any) => {
        if (chip.title.toLowerCase() === c.title.toLowerCase()) {
          this.deleteSearchParams(chip);
          return true;
        }
        return false;
      });
    });
    this.chipsHandle.next(this.chips);
  }

  public getSearchParams(filterName?: string): any {
    return filterName ? this.searchParams[filterName] : this.searchParams;
  }

  public addSearchParams(data) {
    if (data.filterName !== 'offset') {
      this.sharedDataService.setPagCurrentPage({ page: 1 });
      this.deleteSearchParams({ filterName: 'offset' });
    }
    if (!this.searchParams || !this.searchParams[data.filterName]) {
      this.searchParams[data.filterName] = [];
      this.searchParams[data.filterName].push(data.id);
      return;
    }
    if (this.searchParams[data.filterName] && this.searchParams[data.filterName].length) {
      if (_.include(['ordering', 'limit', 'offset'], data.filterName)) {
        this.searchParams[data.filterName].length = 0;
      }
      this.searchParams[data.filterName].push(data.id);
    }
  }

  public clearSearchParams() {
    this.searchParams = {};
  }

  public deleteSearchParams(data) {
    if (!this.searchParams || !this.searchParams[data.filterName]) {
      return;
    }
    this.searchParams[data.filterName] = this.searchParams[data.filterName].filter((id) => {
      return id !== data.id;
    });
    if (!this.searchParams[data.filterName].length || !data.id) {
      delete this.searchParams[data.filterName];
    }
  }

  public searchContacts(link?: string, limit?: number | string, exportFormat?: string): Observable<any> {
    let url = link;
    const options = { headers: this.headers };
    if (!link) {
      url = `${environment.API_URL}/${this.lang}/api/v1/search/${this.searchType}/?search=${this.query.text}`;
      if (this.searchParams) {
        _.each(this.searchParams, (param: any, name: string) => {
          url += `&${name}=${param.join(',')}`;
        });
      }
    }
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (exportFormat) {
      url += `&export=${exportFormat}`;
    }
    this.url = url;
    return this.http.get(
      url,
      options
    );
  }

  public getFilters(): Observable<any> {
    return this.http.get(
      `${environment.API_URL}/${this.lang}/api/v1/filters/`,
      { headers: this.headers }
      );
  }

  public getExportStatus(exportId: string): Observable<any> {
    return this.http.get(
      `${environment.API_URL}/${this.lang}/api/v1/search/export/status/${exportId}/`,
      { headers: this.headers }
    );
  }

  public downloadExport(exportId: string, exportType: string) {
    let downloadDone = false;

    const exportPoll = setInterval(() => {
        if (!downloadDone) {
          this.getExportStatus(exportId).subscribe(
            statusResp => {
              switch (statusResp.status) {
                case 0:
                  this.sharedDataService.setMessageHandel({ message: 'MZ Search.Export Error Message' });
                  clearInterval(exportPoll);
                  break;
                case 1:
                  break;
                case 2:
                  clearInterval(exportPoll);

                  if (!downloadDone) {
                    this.sharedDataService.setMessageHandel({message: 'MZ Search.Export Success Message'});
                    exportType === 'pdf' ? window.open(statusResp.export_file) :
                      window.location.href = statusResp.export_file  + '?response-content-disposition=attachment;';
                    downloadDone = true;
                  }
                  break;
                default:
                  break;
              }
            }
          );
        }
      },
      500
    );
  }

  public getLimitSubFilters(url: string, search?: string, limit?: any, region?: number): Observable<any> {
    let params: HttpParams = new HttpParams();

    if (limit) {
      params = params.set('limit', limit.toString());
    }

    if (region) {
      params = params.set('region', region.toString());
    }

    if (search) {
      params = params.set('search', search.toString());
    }

    return this.http.get(
      url,
      {
        params: params,
        headers: this.headers
      }
    );
  }

  public getLimitSubCities(url: string, search?: string, limit?: any, region?: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', limit.toString());
    params = params.set('region', region.toString());

    const headers = new HttpHeaders(
      {
        'Accept': 'application/json',
      }
    );

    if (search) {
      params = params.set('search', search.toString());
    }
    return this.http.get(url,
      {
        params: params,
        headers
      }
    );
  }

  public getSubFilters(url: string, name: string): Observable<any> {
    return this.http.get(
      url,
      {headers: this.headers}
    );
  }

  private setHeaders() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
    this.headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Token ${this.token}`
    });
  }
}
