<div class="subscription-create">
  <form class="subscription-create__form">

    <label class="subscription-create__form__label">
      Manager: *
      <input type="text" class="text-input">
    </label>
    <label class="subscription-create__form__label">
      Stripe ID:
      <input type="text" class="text-input">
    </label>
    <label class="subscription-create__form__label">
      Start:
      <input type="date" class="text-input">
    </label>
    <label class="subscription-create__form__label">
      End:
      <input type="date" class="text-input">
    </label>

  </form>
</div>
