import { Component } from '@angular/core';
import { Language } from 'angular-l10n';
import { Router } from '@angular/router';
import { SubscriptionFormValuesService } from '../subscription-form-values.service';

@Component({
  selector: 'blitz-subscription-step-4',
  templateUrl: './subscription-step-4.component.html',
  styleUrls: ['./subscription-step-4.component.scss', '../subscription-steps.shared.scss']
})
export class SubscriptionStep4Component {
  @Language() lang: string;

  constructor(public subscriptionFormValuesService: SubscriptionFormValuesService,
              private router: Router) {  }

  redirectToHomepage() {
    this.subscriptionFormValuesService.reset();
    this.router.navigateByUrl('');
  }
}
