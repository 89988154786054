import { Component, OnInit } from '@angular/core';
import { Language, TranslationService } from 'angular-l10n';

import { environment } from '../../environments/environment';

@Component({
  selector: 'blitz-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @Language() lang: string;
  title: string;
  banner: string;
  email = environment.INFO_EMAIL;

  constructor(private translation: TranslationService) {
    this.title = this.translation.translate('Contact.Top Title');
    this.banner = '../../assets/img/contact-top.jpg';
  }

  ngOnInit() {
  }

}
