<blitz-step-bar [stepDirector]="['blue', 'blue', 'blue', 'blue', '']"></blitz-step-bar>
<section class="user-plan">
  <i class="user-plan__icon fas fa-envelope"></i>
  <p class="user-plan__text">
    Un courriel d'activation vient de vous être envoyé afin de finaliser votre inscription.
  </p>
  <a class="common-btn blue" (click)="redirectToHomepage()">
    Retour à l'accueil
  </a>
</section>
