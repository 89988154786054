export class SubscriptionFormServiceStub {
  value = {
    last_name: 'Stub last_name',
    first_name: 'Stub first_name',
    email: 'email@stub.com',
    email_confirm: 'email@stub.com',
    password: 'Qwerty123',
    password_confirm: 'Qwerty123',
    civility: 'mr',
    addons: [],
    subscription_plan: '1',
    address: 'Stub address',
    country: '1',
    region: '2',
    city: '3',
    postcode: '12112',
    phone: '121211212121212',
    company: 'Stub Company',
    'function': 'Stubbing',
    tos_agreement: true,
    newsletter_agreement: true,
    comment: 'Stub comment',
  };

  constructor() { }
}
