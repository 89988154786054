import * as _ from 'underscore';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'blitzPhone'
})
export class PhonePipe implements PipeTransform {

  transform(value: any, lang = 'fr'): any {
    if (!value || _.isEmpty(value)) { return value; }
    return value;
  }

}
