<div class="bank-check-icon">
  <div class="bank-check-icon__wrap">
    <div class="bank-check-icon__wrap__header">
      <div class="bank-check-icon__wrap__header__infos">
        <div class="bank-check-icon__wrap__header__infos__name">
          Your name
        </div>
        <div>
          123 Street
        </div>
        <div>
          City. AA 0000000
        </div>
      </div>
      <div class="bank-check-icon__wrap__header__number">
        0123
      </div>
    </div>
    <div class="bank-check-icon__wrap__content">

      <div class="bank-check-icon__wrap__content__letters">
        <div class="bank-check-icon__wrap__content__letters__order">
          Pay to the<br/>
          order of
        </div>
        <div class="bank-check-icon__wrap__content__letters__amount">
          Dollars
        </div>
      </div>
      <div class="bank-check-icon__wrap__content__numbers">

      </div>
    </div>

    <div class="bank-check-icon__wrap__for">
      For
    </div>

    <div class="bank-check-icon__wrap__account">
      <div class="bank-check-icon__wrap__account__number">
        1234567890
      </div>
      <div class="bank-check-icon__wrap__account__number">
        001234567890
      </div>
      <div class="bank-check-icon__wrap__account__number">
        0123
      </div>
    </div>
