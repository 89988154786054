import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'blitz-step-bar',
  templateUrl: './step-bar.component.html',
  styleUrls: ['./step-bar.component.scss']
})
export class StepBarComponent implements OnInit {
  @Input() stepDirector: any;

  constructor() { }

  ngOnInit() {
  }

}
