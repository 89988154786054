import { Component, Input } from '@angular/core';

@Component({
  selector: 'blitz-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})

export class SpinnerComponent {
  @Input() show: boolean;
  @Input() showSpinnerText: boolean;
}
