<footer class="runway">
  <div class="runway-wrapper">
    <div class="footer-wrapper">
      <div class="web-wrapper">
        <div class="left-part">
          <div class="site-name">{{ title | uppercase }} <sup>MC</sup></div>
          <div class="lower-part">
            <div class="copyright">&copy; {{ currentYear }}. {{ 'Footer.Copyright' | translate:lang }}</div>
          </div>
        </div>
        <div class="middle-part">
          <div class="left-side">
            <div class="about">{{ 'Footer.About' | translate:lang }}</div>
            <div class="lower-part">
              <div class="privacy-policy default-link-wrapper">
                <a class="default-link" [href]="linkToRightOfUse">{{ 'Footer.Privacy' | translate:lang }}</a>
              </div>
              <div class="terms-of-service default-link-wrapper">
                <a class="default-link" [href]="linkToPersonalProtection">{{ 'Footer.Terms' | translate:lang }}</a>
              </div>
              <div class="help-link default-link-wrapper">
                <a class="default-link" [href]="linkToBlog" target="_blank">{{ 'Footer.FAQ' | translate:lang }}</a>
              </div>
            </div>
          </div>
          <div class="right-side">
            <div class="phone">{{ 'Footer.Phone' | translate:lang }}</div>
            <div class="lower-part">
              <div class="phone-number">{{ phone }}</div>
            </div>
          </div>
        </div>
        <div class="right-part">
          <div class="left-side">
            <div class="email">{{ 'Footer.Email' | translate:lang }}</div>
            <div class="lower-part">
              <div class="default-link-wrapper">
                <a class="default-link" href="mailto:{{ email }}">{{ email }}</a>
                <a class="default-link" href="mailto:aidez-nous@datablitz.com">aidez-nous@datablitz.com</a>
              </div>
            </div>
          </div>
          <div class="right-side">
            <div class="social-icons">
              <div class="icon-wrapper">
                <a [href]="linkToFacebook"><i class="fab fa-facebook fa-2x"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-wrapper">
        <div class="links-block">
          <div class="copyright">&copy; {{ 'Footer.CopyrightMobile' | translate:lang }}</div>
          <div class="terms-of-service default-link-wrapper">
            <a class="default-link" [href]="linkToRightOfUse">{{ 'Footer.Terms' | translate:lang }}</a>
          </div>
          <div class="privacy-policy default-link-wrapper">
            <a class="default-link" [href]="linkToPersonalProtection">{{ 'Footer.Privacy' | translate:lang }}</a>
          </div>
          <div class="help-link default-link-wrapper">
            <a class="default-link" [href]="linkToBlog" target="_blank">{{ 'Footer.FAQ' | translate:lang }}</a>
          </div>
        </div>
        <div class="social-icons">
          <div class="icon-wrapper">
            <a [href]="linkToFacebook"><i class="fab fa-facebook fa-2x"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
