import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class ManagerGuard implements CanActivate {
  constructor(private router: Router,
              private authService: AuthService) {
  }

  canActivate(next?: ActivatedRouteSnapshot,
              state?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.isManager();
  }

  private isManager(): any {
    let user = this.authService.getCurrentUser();
    if (user && user.data && !user.data.is_manager) {
      this.router.navigate(['/mz/accounts/my']);
      return false;
    }
    return true;
  }
}
