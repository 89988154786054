import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthService} from '../auth.service';
import {SharedDataService} from '../shared-data.service';

@Injectable()
export class Error401Interceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private sharedDataService: SharedDataService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authService.logout(true, true);
          this.sharedDataService.setMessageHandel({message: 'Header.Token Expired Message', status: 'error'});
        }
        return throwError(err);
      })
    );
  }
}
