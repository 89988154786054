<div (window:scroll)="onScroll()" id="first-header">
  <nav>
    <div class="site-name" [routerLink]="['']">{{ title | uppercase }} <sup>MC</sup></div>
    <div class="nav-btns" *ngIf="menuList?.length">
      <div class="default-link-wrapper" *ngFor="let item of menuList" routerLinkActive="active-link">
        <a *ngIf="item['link']" class="default-link" [routerLink]="item['link']">{{ item['title'] }} <sup *ngIf="item['id'] === 'media_match'">MC</sup></a>
        <a *ngIf="!item['link']" class="default-link" target='_blank' [href]="item['external_link']">{{ item['title'] }} <sup *ngIf="item['id'] === 'media_match'">MC</sup></a>
      </div>
    </div>

    <div class="sign-in-btn" *ngIf="showLogin">
      <img class="sign-in-icon" (click)="toggleLogin(); false" src="../../assets/img/header-sign-in-icon.png">
      <div class="default-link-wrapper" routerLinkActive="active-link">
        <a class="default-link" (click)="toggleLogin(); false" href="#">{{ 'Header.SignIn' | translate:lang }}</a>
      </div>
    </div>
    <div class="notification" *ngIf="showMemberName && notifications.length" (click)="notificationPopover.toggle($event)">
      <i class="fas fa-bell"></i>
      <div class="notification__alert">
        {{ getNumberOfNotifications() }}
      </div>
      <blitz-popover class="blitz-popover notification__popover" #notificationPopover mdl-shadow="8">
        <div class="info-popover-content">
          <div class="info-popover-text-block">
            <div *ngFor="let notification of notifications" class="notification__popover__item">
              <a (click)="visitNotification(notification)" class="notification__popover__item__name">
                {{ notification.name }}
              </a>
              <div>
                <a (click)="markNotificationAsRead(notification)" class="notification__popover__item__delete">
                  <i class="fas fa-times"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </blitz-popover>
    </div>
    <div class="member-name-wrapper" *ngIf="showMemberName" (click)="accPopover.toggle($event)">
      <div class="member-icon"><img src="../../assets/img/header-sign-in-icon.png"></div>
      <div class="member-name">
        {{ 'MZ Header.Hello' | translate:lang }}{{ user?.data?.first_name }}
      </div>
      <blitz-popover class="blitz-popover" #accPopover mdl-shadow="8">
        <div class="info-popover-content">
          <div class="info-popover-text-block">
            <div><a routerLink="/mz/accounts/my">{{ 'Header.My Account' | translate:lang }}</a></div>
            <div><a routerLink="/admin" *ngIf="authService.isSuperuser()">{{ 'Header.Admin Panel' | translate:lang }}</a></div>
            <div><a (click)="authService.logout()">{{ 'Header.Logout' | translate:lang }}</a></div>
          </div>
        </div>
      </blitz-popover>
    </div>

    <div class="hamburger-menu">
      <blitz-slide-menu [onUserChange]="user" (toggleHeaderBack)="toggleHeader()"></blitz-slide-menu>
    </div>
  </nav>
  <blitz-login [showModal]="showModal" *ngIf="showLogin" (close)="close()"></blitz-login>
</div>
<blitz-second-header *ngIf="isMember && !hideSecondHeader"
                     (onSearchEmitter)="onSearch($event)"></blitz-second-header>
