
import {fromEvent as observableFromEvent,  Observable ,  BehaviorSubject } from 'rxjs';

import {map, distinctUntilChanged} from 'rxjs/operators';
import { Injectable } from '@angular/core';




export type DeviceType = 'mobile' | 'tablet' | 'desktop' | 'fullResolution';

@Injectable()
export class DeviceService {
  public device: Observable<DeviceType>;

  constructor() {
    const checkDevice = new BehaviorSubject<DeviceType>(this.getWindowSize());
    this.device = checkDevice.pipe(distinctUntilChanged());

    observableFromEvent(window, 'resize').pipe(
      map(this.getWindowSize))
      .subscribe(checkDevice);
  }

  private getWindowSize(): DeviceType {
    let device: DeviceType;
    switch (true) {
      case window.innerWidth < 768:
        device = 'mobile';
        break;
      case window.innerWidth > 767 && window.innerWidth < 1024:
        device = 'tablet';
        break;
      case window.innerWidth > 1023 && window.innerWidth < 1229:
        device = 'desktop';
        break;
      case window.innerWidth > 1228:
      default:
        device = 'fullResolution';
        break;
    }
    return device;
  };
}
