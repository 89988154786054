import { HttpHeaders } from '@angular/common/http';
import {CookieService} from 'ngx-cookie';
import {environment} from '../../environments/environment';

export default class GlobalService {

  lang: string;

  constructor(public cookieService: CookieService) {
    if (this.cookieService.get('defaultLocale')) {
      this.lang = this.cookieService.get('defaultLocale').split('-')[0];
    } else {
      this.lang = environment.default_language;
    }
  }

  getHeaders(contentType = 'application/json') {
    const options = {};

    const currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : {};
    const token = currentUser.token;
    if (contentType) {
      options['Content-Type'] = contentType;
      options['Accept'] = contentType;
    }
    if (token) {
      options['Authorization'] = 'Token ' + token;
    }
    const header = new HttpHeaders(options);
    return header;
  }
}
