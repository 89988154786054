
import {takeUntil} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedDataService } from '../../shared-data.service';
import { Subject } from 'rxjs';


import { Language } from 'angular-l10n';

@Component({
  selector: 'blitz-popup-notification',
  templateUrl: './popup-notification.component.html',
  styleUrls: ['./popup-notification.component.scss']
})
export class PopupNotificationComponent implements OnInit, OnDestroy {
  @Language() lang: string;
  public message: string;
  public status: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private timeoutDelay = 5000;
  private timeoutId: number;

  constructor(private sharedDataService: SharedDataService) {
  }

  ngOnInit() {
    this.sharedDataService.getMessageHandel().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((msg: { [key: string]: string }) => {
        this.message = msg && msg.message ? msg.message : '';
        this.status = msg && msg.status ? msg.status : '';
        let delay: number = msg && msg.delay ? Number(msg.delay) : this.timeoutDelay;
        if (this.message) {
          this.scrollToTop();
          this.timeoutId = window.setTimeout(() => {
            this.clearMsg();
          },
          delay
          );
        }
      });
  }

  public ngOnDestroy() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    this.clearMsg();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private clearMsg() {
    this.sharedDataService.setMessageHandel();
  }

  private scrollToTop() {
    window.scrollTo(0, 0);
  }
}
