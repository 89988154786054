import { Addon } from './addon';
import { Member } from './member';
import { Subscription } from './subscription';

export class Profile {
  'id': number;
  'email': string;
  'accounting_email': string;
  'accounting_first_name': string;
  'accounting_last_name': string;
  'first_name': string;
  'last_name': string;
  'civility': string;
  'address': string;
  'postcode': string;
  'phone': string;
  'company': string;
  'function': string;
  'comment': string;
  'tos_agreement': boolean;
  'is_manager': boolean;
  'is_superuser'?: boolean;
  'newsletter_agreement': boolean;
  'country': { id: number, name: string };
  'city': { id: number, name: string };
  'region': { id: number, name: string };
  'manager_subscription'?: Subscription;
  'addons': Addon[];
  'has_active_mm': boolean;
  'members': Member[];
  'permissions': any[];

  subscription_renew?: Subscription;
  subscription_request?: Subscription;
  subscriptions?: Subscription;
  can_buy_solo?: boolean;
}
