import {takeUntil} from 'rxjs/operators';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Language } from 'angular-l10n';

import { SharedDataService } from '../../shared-data.service';
import { toBoolean } from '@angular-mdl/core';

@Component({
  selector: 'blitz-centered-popover',
  templateUrl: './centered-popover.component.html',
  styleUrls: ['./centered-popover.component.scss']
})
export class CenteredPopoverComponent implements OnInit, OnDestroy {
  @Language() lang: string;
  isVisible = false;
  message: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private sharedDataService: SharedDataService) { }

  ngOnInit() {
    this.sharedDataService.getCenteredPopoverMessage().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((msg: string) => {
        this.isVisible = toBoolean(msg);
        this.message = msg;
      });
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: Element) {
    this.hideOnClick(targetElement);
  }

  @HostListener('document:touchend', ['$event.target'])
  public onTouch(targetElement: Element) {
    this.hideOnClick(targetElement);
  }

  public close() {
    this.sharedDataService.setCenteredPopoverMessage();
  }

  private hideOnClick(targetElement: Element) {
    if (targetElement.className.includes('section-shadow')) {
      this.close();
    }
  }
}
