import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionService } from '../../../services/subscription.service';
import { isNull } from 'util';
import { Subscription } from '../../../shared';

@Component({
  selector: 'blitz-admin-subscription-list',
  templateUrl: './admin-subscription-list.component.html',
  styleUrls: ['./admin-subscription-list.component.scss']
})
export class AdminSubscriptionListComponent implements OnInit {

  settings = {
    previous: false,
    next: false,
    numberOfPage: 0,
    page: 0,
    noDataText: 'Aucune subscription',
    clickable: true,
    columns: [
      {
        name: 'id',
        title: 'ID'
      },
      {
        name: 'name',
        title: 'Nom complet'
      },
      {
        name: 'email',
        title: 'Courriel'
      },
      {
        name: 'company',
        title: 'Compagnie'
      },
      {
        name: 'start',
        title: 'Début'
      },
      {
        name: 'end',
        title: 'Fin'
      },
      {
        name: 'auto_renew',
        title: 'Auto-renouvellement',
        type: 'boolean'
      },
      {
        name: 'is_approved',
        title: 'Approuvé',
        type: 'boolean'
      }
    ]
  };
  page = 1;

  data: Subscription[] = [];

  approvalFilter = 'all';
  autoRenewFilter = 'all';
  paymentCompletedFilter = 'all';

  searchFilter = null;
  typingTimer;
  doneTypingInterval = 500;

  constructor(private router: Router,
              private subscriptionService: SubscriptionService) {
  }

  ngOnInit() {
    this.page = 1;
    this.refreshSubscriptions();
  }

  changePage(index: number) {
    this.page = index;
    this.refreshSubscriptions();
  }

  refreshSubscriptions(page = this.page) {
    const filters = this.getFilters();
    const limit = 30;
    this.subscriptionService.list(filters, limit, limit * (page - 1)).subscribe(
      data => {
        this.settings.numberOfPage = Math.ceil(data.count / limit);
        this.settings.page = page;
        this.settings.previous = !isNull(data.previous);
        this.settings.next = !isNull(data.next);
        this.data = data.results.map(s => this.formatSubscription(s));
      }
    );
  }

  getFilters() {
    const filters = [];
    if (this.searchFilter) {
      filters.push(
        {
          name: 'search',
          value: this.searchFilter
        }
      );
    }
    if (this.approvalFilter) {
      if (this.approvalFilter !== 'all') {
        filters.push(
          {
            name: 'is_approved',
            value: this.approvalFilter === 'approved'
          }
        );
      }
    }
    if (this.autoRenewFilter) {
      if (this.autoRenewFilter !== 'all') {
        filters.push(
          {
            name: 'auto_renew',
            value: this.autoRenewFilter === 'true'
          }
        );
      }
    }
    if (this.paymentCompletedFilter) {
      if (this.paymentCompletedFilter !== 'all') {
        filters.push(
          {
            name: 'payment__completed',
            value: this.paymentCompletedFilter === 'true'
          }
        );
      }
    }
    return filters;
  }

  goToDetail(subscription) {
    this.router.navigate(['/admin/subscription/' + subscription.id]);
  }

  formatDay(date) {
    if (!date) {
      return '-';
    }
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    return new Date(date).toLocaleDateString('fr', options);
  }

  changeSearch() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(
      () => {
        this.refreshSubscriptions();
      },
      this.doneTypingInterval
    );
  }

  formatSubscription(subscription) {
    return {
      name: subscription.manager.first_name + ' ' + subscription.manager.last_name,
      email: subscription.manager.email,
      company: subscription.manager.company,
      id: subscription.id,
      start: this.formatDay(subscription.start),
      end: this.formatDay(subscription.end),
      auto_renew: subscription.auto_renew,
      is_approved: subscription.is_approved
    };
  }
}
